import { Link } from 'react-router-dom';

import { Flex, Text } from '@raystack/apsara';
import { V1Beta1Organization } from '@raystack/frontier';
import { Container } from '@raystack/frontier/react';

import { styled } from '@src/stitches.config';

import OnboardingLogout from './OnboardingLogout';
import Logo from './logo';
import { Layout, SubHeading, Wrapper } from './styles';

const StyledLink = styled(Link, {
  display: 'flex',
  minWidth: '240px',
  padding: ' var(--gap_3) var(--gap_4)',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'var(--S)',
  background: 'var(--background-accent-inverted)',
  fontSize: '$1',
  fontWeight: 500,
  color: 'var(--foreground-inverted)',

  '&:hover': {
    backgroundColor: 'var(--background-accent-inverted-hover)'
  }
});

interface PendingOrgPageProps {
  organization: V1Beta1Organization;
}

export default function PendingOrgPage({ organization }: PendingOrgPageProps) {
  const metadata = organization?.metadata as Record<string, string>;
  return (
    <Layout>
      <OnboardingLogout />
      <Wrapper css={{ width: '500px', marginTop: '100px' }}>
        <Container>
          <Flex>
            <Logo />
          </Flex>
          <Flex direction='column' align='center' gap='medium'>
            <Text size={9}>Request submitted for organization</Text>
            <SubHeading size={4}>
              Our team will look into your request and reach out to you soon at{' '}
              {metadata?.poc}
            </SubHeading>
          </Flex>
          <StyledLink to='/organizations'>Continue</StyledLink>
        </Container>
      </Wrapper>
    </Layout>
  );
}
