import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

export const announcementMap = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR'
} as const;

export type AnnouncementType = keyof typeof announcementMap;

export interface Announcement {
  type?: AnnouncementType;
  message?: string;
  action?: (() => void) | null;
  actionLabel?: string;
  closable?: boolean;
}

export interface InitialState {
  announcement: Announcement;
  showNotifications: boolean;
}

const initialState = {
  announcement: {},
  showNotifications: false
};

type Reducers = {
  setAnnouncement: CaseReducer<InitialState, PayloadAction<Announcement>>;
  resetAnnouncement: CaseReducer<InitialState>;
  toggleNotifications: CaseReducer<InitialState>;
  closeNotifications: CaseReducer<InitialState>;
};

export const appSlice = createSlice<InitialState, Reducers, 'app'>({
  name: 'app',
  initialState,
  reducers: {
    setAnnouncement: (state, action) => {
      state.announcement = action.payload;
    },
    resetAnnouncement: state => {
      state.announcement = {};
    },
    toggleNotifications: state => {
      state.showNotifications = !state.showNotifications;
    },
    closeNotifications: state => {
      state.showNotifications = false;
    }
  }
});

export const {
  setAnnouncement,
  resetAnnouncement,
  toggleNotifications,
  closeNotifications
} = appSlice.actions;

export default appSlice.reducer;
