import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { V1Beta1Project } from '@raystack/frontier';
import { useFrontier } from '@raystack/frontier/react';

import {
  setIsProjectListLoading,
  setProjectList
} from '@src/redux/_reducers/projectReducer';
import { ProjectErrorHelper } from '@src/utils/helpers/ProjectHelpers';

interface useProjectsOptions {
  onError?: (err: unknown) => void;
  onSuccess?: (users: V1Beta1Project[], meta: { id: string }) => void;
}

export default function useProjects(options: useProjectsOptions = {}) {
  const { onError, onSuccess } = {
    onError: () => {},
    onSuccess: () => {},
    ...options
  };

  const dispatch = useDispatch();
  const [projects, setProjects] = useState<V1Beta1Project[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { client } = useFrontier();

  const fetchProjects = useCallback(
    async (orgId: string) => {
      const resp = await client?.frontierServiceListProjectsByCurrentUser({
        org_id: orgId,
        non_inherited: true
      });
      const projectList = resp?.data?.projects || [];
      return projectList;
    },
    [client]
  );

  const fetchAndSetProjects = useCallback(
    async (org_id: string) => {
      try {
        dispatch(setIsProjectListLoading(true));
        setIsLoading(true);
        const projectList = await fetchProjects(org_id);
        dispatch(setProjectList(projectList));
        setProjects(projectList);
        onSuccess(projectList, { id: org_id });
      } catch (err: unknown) {
        console.error(err);
        ProjectErrorHelper(err);
        onError(err);
      } finally {
        dispatch(setIsProjectListLoading(false));
        setIsLoading(false);
      }
    },
    [dispatch, fetchProjects, onError, onSuccess]
  );

  return {
    projects,
    isLoading,
    getProjects: fetchAndSetProjects,
    fetchProjects: fetchProjects
  };
}
