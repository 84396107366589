import { Fragment } from 'react';

import { ChevronRightIcon, Cross2Icon } from '@radix-ui/react-icons';
import { Button, Flex, Separator, Text } from '@raystack/apsara';
import clsx from 'clsx';

import styles from './Header.module.css';

interface HeaderProps {
  children: React.ReactNode;
  onClose?: React.MouseEventHandler;
  hasSeparator?: boolean;
  className?: string;
  disabled?: boolean;
  actions?: React.ReactNode;
}

interface HeaderText {
  children: React.ReactNode;
  size?: 'large' | 'medium';
  onClick?: () => void;
}

interface HeaderBreadcrumbs {
  list: { text: string; onClick: HeaderText['onClick'] }[];
  size: HeaderText['size'];
}

const HeaderText = ({ children, size = 'medium', onClick }: HeaderText) => {
  return (
    <Text
      data-test-id={`text-${children}`}
      weight={500}
      size={size === 'medium' ? 4 : 5}
      onClick={onClick}
      className={clsx(onClick && styles.textClick)}
    >
      {children}
    </Text>
  );
};

const HeaderBreadcrumbs = ({ list, size }: HeaderBreadcrumbs) => {
  const listLen = list.length - 1;

  return (
    <Flex gap='small' align='center' className={styles.breadcrumbs}>
      {list.map(({ text, onClick }, idx) => (
        <Fragment key={idx}>
          <HeaderText
            data-test-id={`text-${text}`}
            size={size}
            onClick={onClick}
          >
            {text}
          </HeaderText>

          {idx !== listLen && <ChevronRightIcon className={styles.icon} />}
        </Fragment>
      ))}
    </Flex>
  );
};

const HeaderRoot = ({
  children,
  onClose,
  hasSeparator = true,
  disabled,
  actions,
  className
}: HeaderProps) => {
  return (
    <>
      <Flex
        align='center'
        justify='between'
        className={clsx(styles.header, className)}
      >
        {children}

        <Flex gap='small'>
          {actions}

          {onClose && (
            <Button
              data-test-id='close-header-btn'
              className={styles.button}
              onClick={onClose}
              disabled={disabled}
            >
              <Cross2Icon className={styles.icon} />
            </Button>
          )}
        </Flex>
      </Flex>

      {hasSeparator && <Separator />}
    </>
  );
};

export const Header = Object.assign(HeaderRoot, {
  Text: HeaderText,
  Breadcrumbs: HeaderBreadcrumbs
});
