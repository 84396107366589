import { createContext, useEffect, useState } from 'react';

import { useFrontier } from '@raystack/frontier/react';

import { FLAGS, FLAGS_LIST } from './flags';

export type FlagsValueType = string | number | boolean;
export type FlagsType = Record<string, FlagsValueType>;

interface FlagsContextType {
  flags: FlagsType;
}

export const FlagsContext = createContext<Partial<FlagsContextType>>({
  flags: undefined
});

const envPrefix = 'VITE_FLAGS';
const valueSuffix = 'ENABLED';
const usersSuffix = 'USERS';
const orgsSuffix = 'ORGS';
const localStoragePrefix = 'FLAGS';

const readEnvData = (key: string) => {
  return import.meta.env[key];
};

const readLocalStorageData = (key: string) => {
  return localStorage.getItem(key);
};

const checkValueInEnvList = (envKey: string, value: FlagsValueType) => {
  const envValue = readEnvData(envKey);
  const parsedList =
    envValue !== undefined ? JSON.parse(envValue as string) : [];
  if (Array.isArray(parsedList) && value && parsedList.includes(value)) {
    return true;
  }
  return false;
};

function getFlagsKeyMap(name: keyof typeof FLAGS) {
  return {
    envValueKey: `${envPrefix}_${name}_${valueSuffix}`,
    localStorageKey: `${localStoragePrefix}_${name}_${valueSuffix}`,
    envUsersKey: `${envPrefix}_${name}_${usersSuffix}`,
    envOrgsKey: `${envPrefix}_${name}_${orgsSuffix}`
  };
}

function toBoolean(value: FlagsValueType) {
  const parsedValue = isNaN(value as number)
    ? value
    : parseInt(value as string);

  switch (typeof parsedValue) {
    case 'string':
      return parsedValue.toLowerCase() === 'true';
    case 'number':
      return parsedValue > 0;
    case 'boolean':
      return parsedValue;
    default:
      return false;
  }
}

export function initFlags(userEmail: string, orgName: string) {
  const valueMap = FLAGS_LIST.reduce<Record<string, FlagsValueType>>(
    (acc, flagInfo) => {
      const flagName = flagInfo.name;
      acc[flagName] = flagInfo.defaultValue;

      const keySet = getFlagsKeyMap(flagName);

      const envValue = readEnvData(keySet.envValueKey);
      if (envValue != undefined) {
        acc[flagName] = toBoolean(envValue);
      }

      const isOrgEnabled = checkValueInEnvList(keySet.envOrgsKey, orgName);
      if (isOrgEnabled) {
        acc[flagName] = true;
      }

      const isUserEnabled = checkValueInEnvList(keySet.envUsersKey, userEmail);
      if (isUserEnabled) {
        acc[flagName] = true;
      }

      const localStorageValue = readLocalStorageData(keySet.localStorageKey);

      if (localStorageValue != null) {
        acc[flagName] = toBoolean(Number(localStorageValue));
      }
      return acc;
    },
    {}
  );

  return valueMap;
}

export function FlagsProvider({ children }: { children: React.ReactNode }) {
  const [flags, setFlags] = useState<FlagsType>({});
  const { user, activeOrganization } = useFrontier();

  const userEmail = user?.email || '';
  const orgName = activeOrganization?.name || '';

  useEffect(() => {
    const newFlags = initFlags(userEmail, orgName);
    setFlags(newFlags);
  }, [userEmail, orgName]);

  return (
    <FlagsContext.Provider value={{ flags }}>{children}</FlagsContext.Provider>
  );
}
