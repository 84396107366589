import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Polygon } from '@turf/turf';
import {
  DrawPolygonMode,
  DrawRectangleMode,
  ModifyMode,
  ViewMode
} from 'nebula.gl';

import { Coordinates, MapTypes } from '@src/types/maps';
import { SATELLITE_LISTS } from '@src/utils/constants/COSMOS_CONSTANTS';

interface MeasureJson {
  type: 'FeatureCollection';
  features: string[];
}

type Modes =
  | typeof DrawPolygonMode
  | typeof ModifyMode
  | typeof DrawRectangleMode
  | typeof ViewMode;

export interface InitialState {
  tileLoader: boolean;
  selectedImages: string[];
  previewTile: string[];
  center: Coordinates;
  zoom: number;
  bounds: [Coordinates, Coordinates];
  area: number;
  mapEdit: boolean;
  drawEdit: boolean;
  mapType: MapTypes;
  controller: boolean;
  viewport: Polygon;
  cosmosImages: string[];
  cosmosSource: string[];
  isMeasure: boolean;
  measureJson: MeasureJson;
  PrevMeasureJson: MeasureJson;
  selectedFeatureIndexes: string[];
  PrevSelectedFeatureIndexes: string[];
  mode: Modes;
}

const initialState: InitialState = {
  tileLoader: false,
  selectedImages: [],
  previewTile: [],
  center: [34.2331797157622, -102.41094535172934],
  zoom: 13,
  bounds: [
    [-102.489223, 34.179288],
    [-102.332497, 34.286581]
  ],
  area: 0,
  mapEdit: false,
  drawEdit: false,
  mapType: localStorage.getItem('mapType')
    ? (JSON.parse(localStorage.getItem('mapType') || '') as MapTypes)
    : 'Street',
  controller: true,
  viewport: {},
  cosmosImages: [],
  cosmosSource: [SATELLITE_LISTS[0].satellite_id],
  isMeasure: false,
  measureJson: {
    type: 'FeatureCollection',
    features: []
  },
  PrevMeasureJson: {
    type: 'FeatureCollection',
    features: []
  },
  selectedFeatureIndexes: [],
  PrevSelectedFeatureIndexes: [],
  mode: DrawPolygonMode
};

type Reducers = {
  setCosmosSource: CaseReducer<InitialState, PayloadAction<string>>;
  updateCosmosImages: CaseReducer<InitialState, PayloadAction<string[]>>;
  updateViewPort: CaseReducer<InitialState, PayloadAction<Polygon>>;
  updateController: CaseReducer<InitialState, PayloadAction<boolean>>;
  setSelectedImages: CaseReducer<InitialState, PayloadAction<string[]>>;
  resetSelectedImages: CaseReducer<InitialState>;
  setCenter: CaseReducer<InitialState, PayloadAction<Coordinates>>;
  setZoom: CaseReducer<InitialState, PayloadAction<number>>;
  setBounds: CaseReducer<
    InitialState,
    PayloadAction<[Coordinates, Coordinates]>
  >;
  setArea: CaseReducer<InitialState, PayloadAction<number>>;
  setPreviewTile: CaseReducer<InitialState, PayloadAction<string[]>>;
  setMapEdit: CaseReducer<InitialState, PayloadAction<boolean>>;
  setEditDraw: CaseReducer<InitialState, PayloadAction<boolean>>;
  setMapType: CaseReducer<InitialState, PayloadAction<MapTypes>>;
  setTileLoader: CaseReducer<InitialState, PayloadAction<boolean>>;
  setIsMeasure: CaseReducer<InitialState, PayloadAction<boolean>>;
  setMeasureJson: CaseReducer<InitialState, PayloadAction<MeasureJson>>;
  setPrevMeasureJson: CaseReducer<InitialState, PayloadAction<MeasureJson>>;
  setSelectedFeatureIndexes: CaseReducer<InitialState, PayloadAction<string[]>>;
  setPrevSelectedFeatureIndexes: CaseReducer<
    InitialState,
    PayloadAction<string[]>
  >;
  setMode: CaseReducer<InitialState, PayloadAction<Modes>>;
};

export const mapSlice = createSlice<InitialState, Reducers, 'map'>({
  name: 'map',
  initialState,
  reducers: {
    setCosmosSource: (state, action) => {
      if (state.cosmosSource.includes(action.payload)) {
        state.cosmosSource = state.cosmosSource.filter(
          d => d !== action.payload
        );
      } else {
        state.cosmosSource = [...state.cosmosSource, action.payload];
      }
    },
    updateCosmosImages: (state, action) => {
      state.cosmosImages = action.payload;
    },
    updateViewPort: (state, action) => {
      state.viewport = action.payload;
    },
    updateController: (state, action) => {
      state.controller = action.payload;
    },
    setSelectedImages: (state, action) => {
      state.selectedImages = action.payload;
    },
    resetSelectedImages: state => {
      state.selectedImages = [];
    },
    setCenter: (state, action) => {
      state.center = action.payload;
    },
    setZoom: (state, action) => {
      state.zoom = action.payload;
    },
    setBounds: (state, action) => {
      state.bounds = action.payload;
    },
    setArea: (state, action) => {
      state.area = action.payload;
    },
    setPreviewTile: (state, action) => {
      state.previewTile = action.payload;
    },
    setMapEdit: (state, action) => {
      state.mapEdit = action.payload;
    },
    setEditDraw: (state, action) => {
      state.drawEdit = action.payload;
    },
    setMapType: (state, action) => {
      state.mapType = action.payload;
      localStorage.setItem('mapType', JSON.stringify(action.payload));
    },
    setTileLoader: (state, action) => {
      state.tileLoader = action.payload;
    },
    setIsMeasure: (state, action) => {
      state.isMeasure = action.payload;
    },
    setMeasureJson: (state, action) => {
      state.measureJson = action.payload;
    },
    setPrevMeasureJson: (state, action) => {
      state.PrevMeasureJson = action.payload;
    },
    setSelectedFeatureIndexes: (state, action) => {
      state.selectedFeatureIndexes = action.payload;
    },
    setPrevSelectedFeatureIndexes: (state, action) => {
      state.PrevSelectedFeatureIndexes = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setSelectedImages,
  setCenter,
  setBounds,
  resetSelectedImages,
  setZoom,
  setArea,
  setPreviewTile,
  setMapEdit,
  setEditDraw,
  setTileLoader,
  updateController,
  updateViewPort,
  updateCosmosImages,
  setCosmosSource,
  setIsMeasure,
  setMeasureJson,
  setPrevMeasureJson,
  setSelectedFeatureIndexes,
  setPrevSelectedFeatureIndexes,
  setMode,
  setMapType
} = mapSlice.actions;

export default mapSlice.reducer;
