import { point } from '@turf/turf';

import api from '@src/api';
import store from '@src/redux/store';

// Fetch spectral data
export async function fetchSpectralData(
  spectralPoint,
  points,
  setPoints,
  setSpectralLoader
) {
  const state = store.getState();
  const activeAoi = state.projects.activeAoi;
  setSpectralLoader(true);

  const geojsonPoint = point(spectralPoint.coordinates);

  try {
    const res = await api.dataStore.post(`/${activeAoi.id}/signature`, {
      point: { ...geojsonPoint.geometry }
    });
    setPoints([
      ...points.map(point => {
        if (point.id === spectralPoint.id) {
          return {
            ...point,
            response: { ...res.data }
          };
        }
        return point;
      })
    ]);
  } catch (error) {
    console.error(error);
  } finally {
    setSpectralLoader(false);
  }
}

function luminance(r, g, b) {
  const a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function contrast(rgb1, rgb2) {
  const lum1 = luminance(rgb1.r, rgb1.g, rgb1.b);
  const lum2 = luminance(rgb2.r, rgb2.g, rgb2.b);

  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);

  return Number((brightest + 0.05) / (darkest + 0.05));
}

export function findContrastRanges({ r, g, b }) {
  const baseRgb = { r: Number(r), g: Number(g), b: Number(b) };
  const contrastRanges = [];

  for (let r = 0; r <= 255; r += 51) {
    for (let g = 0; g <= 255; g += 51) {
      for (let b = 0; b <= 255; b += 51) {
        const contrastRatio = contrast(baseRgb, { r, g, b });

        if (contrastRatio > 2) {
          contrastRanges.push({
            color: { r, g, b },
            contrast: contrastRatio.toFixed(2)
          });
        }
      }
    }
  }

  return contrastRanges;
}

export function convertRgbToRgbString({ r, g, b }) {
  return `rgb(${r}, ${g}, ${b})`;
}

function getNumInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomMemoizedIndex(min, max) {
  const index = new Set();
  const memo = {};

  return function (key) {
    if (memo[key]) return memo[key];

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const randomIdx = getNumInRange(min, max);

      if (!index.has(randomIdx)) {
        memo[key] = randomIdx;
        break;
      }
    }

    return memo[key];
  };
}

export function AddDynamicPointFunc({
  points,
  updatePoints,
  setSpectralLoader,
  colors,
  MapCenter,
  getRandomIndex
}) {
  // Get the next available point name
  const pointNames = Array.from({ length: 99 }, (_, i) =>
    (i + 1).toString().padStart(2, '0')
  );
  const availablePoint = pointNames.find(
    name => !points.map(p => p.name).includes(name)
  );

  if (availablePoint) {
    // this is an issue from leaflet migration, where lat long format is ordering is misplaced
    const coordinates =
      MapCenter && MapCenter.length >= 2
        ? [MapCenter[1].toFixed(6), MapCenter[0].toFixed(6)]
        : [];

    const point = {
      name: availablePoint,
      id: availablePoint,
      color: convertRgbToRgbString(
        colors[getRandomIndex(availablePoint)].color
      ),
      coordinates: coordinates.map(Number)
    };
    updatePoints(point, true, setSpectralLoader);
  }
}
