import googleAnalytics from '@analytics/google-analytics';
import googleTagManager from '@analytics/google-tag-manager';
import Analytics from 'analytics';

const measurementId = import.meta.env.VITE_GA_MEASUREMENT_ID;
const tagManagerId = import.meta.env.VITE_TAG_MANAGER_ID;

const analyticsInstance = Analytics({
  app: 'Aurora',
  plugins: [
    googleAnalytics({
      measurementIds: [measurementId]
    }),
    googleTagManager({
      containerId: tagManagerId
    })
  ]
});

export default analyticsInstance;
