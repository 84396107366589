import { configureStore } from '@reduxjs/toolkit';

import ActivityReducer from './_reducers/ActivityReducer';
import appReducer from './_reducers/appReducer';
import authReducer from './_reducers/authReducer';
import blockReducer from './_reducers/blockReducer';
import filtersReducer from './_reducers/filtersReducer';
import mapSlice from './_reducers/mapControl';
import projectReducer from './_reducers/projectReducer';
import sourceActionReducer from './_reducers/sourceActionReducer';
import workFlowReducer from './_reducers/workFlowReducer';

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    map: mapSlice,
    filters: filtersReducer,
    projects: projectReducer,
    activity: ActivityReducer,
    sourceActions: sourceActionReducer,
    workflow: workFlowReducer,
    blocks: blockReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export default store;
