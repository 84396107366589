import ReactLoading from 'react-loading';
import { ScaleLoader } from 'react-spinners';

import { Flex, Text } from '@raystack/apsara';

import classes from '@src/components/Custom/Spinner/style.module.css';

// used only in workflow list and job list
export function ScaleLoaderState({ text }) {
  return (
    <Flex
      direction='column'
      align='center'
      gap='medium'
      style={{ width: '100%' }}
    >
      <Text size={5} style={{ color: 'var(--foreground-subtle)' }}>
        {text}
      </Text>
      <ScaleLoader
        color={'var(--foreground-subtle)'}
        height={24}
        margin={1}
        radius={4}
        width={2.8}
        className='mx-auto'
      />
    </Flex>
  );
}

// primary spin primitive
export function Spin() {
  return (
    <ReactLoading
      height={16}
      width={16}
      type='spin'
      className={classes.spinner}
    />
  );
}

// primary accented spin primitive
export function AccentSpin() {
  return (
    <ReactLoading
      height={16}
      width={16}
      type='spin'
      color='var(--foreground-accent)'
    />
  );
}

// used in private route, editor and jobs files
export function FullPageSpinner({ text }) {
  return (
    <Flex className={classes.fullpageSpinWrapper} justify='between'>
      <div style={{ margin: 'auto' }}>
        <Flex direction='column' align='center' gap='medium'>
          <Text
            size={5}
            weight={500}
            style={{ color: 'rgba(255, 255, 255, 0.7)' }}
          >
            {text}
          </Text>
          <ScaleLoader
            color={'rgba(255, 255, 255, 0.5)'}
            height={40}
            margin={2}
            radius={4}
            width={2.8}
          />
        </Flex>
      </div>
    </Flex>
  );
}

// used in jobs and outputwrapper
export function SpreadSpinBlurred({ text }) {
  return (
    <Flex className={classes.fullpageSpinWrapper} justify='between'>
      <div style={{ margin: 'auto' }}>
        <Flex direction='column' align='center' gap='medium'>
          {' '}
          <Text
            size={4}
            weight={500}
            style={{ color: 'rgba(255, 255, 255, 0.7)' }}
          >
            {text}
          </Text>
          <ScaleLoader
            color={'rgba(255, 255, 255, 0.7)'}
            height={24}
            margin={1}
            radius={4}
            width={2.8}
            className='mx-auto'
          />
        </Flex>
      </div>
    </Flex>
  );
}

// used in insights and workflows for showing when vector data is loading
export const LayerLoader = ({ text }) => {
  return (
    <Flex className={classes.layerLoaderWrapper}>
      <Flex
        className={classes.layerLoaderDiv}
        gap='medium'
        direction='column'
        align='center'
      >
        <ScaleLoader
          color={'rgba(255, 255, 255, 0.8)'}
          height={14}
          margin={1}
          radius={4}
          width={2}
          className='mx-auto'
        />
        <Text style={{ color: 'rgba(255, 255, 255, 0.8)' }}>{text}</Text>
      </Flex>
    </Flex>
  );
};
