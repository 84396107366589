import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  polls: JSON.parse(localStorage.getItem('user'))?.userDataPref?.polls
    ? JSON.parse(localStorage.getItem('user')).userDataPref.polls
    : [],
  completedJobs: [],
  failedJobs: []
};

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setPolls: (state, action) => {
      state.polls = action.payload;

      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.userDataPref.polls = state.polls;
      localStorage.setItem('user', JSON.stringify(currentObj));
    },
    updatePoll: (state, action) => {
      // let filterePolls = state.polls.filter((i)=> i.id !== action.payload.id)
      state.polls.forEach((poll, i) => {
        if (action.payload.id === poll.id) {
          state.polls[i].progress = action.payload.progress;
        }
      });

      state.polls = [...state.polls];

      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.userDataPref.polls = state.polls;
      localStorage.setItem('user', JSON.stringify(currentObj));
    },
    removePoll: (state, action) => {
      let filteredPolls = state.polls.filter(
        poll => poll.id !== action.payload.id
      );
      state.polls = filteredPolls;

      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.userDataPref.polls = state.polls;
      localStorage.setItem('user', JSON.stringify(currentObj));
    },
    completeJob: (state, action) => {
      let filtered = state.polls.filter(i => i.id !== action.payload.id);
      state.polls = filtered;
      state.completedJobs.push(action.payload);

      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.userDataPref.polls = state.polls;
      localStorage.setItem('user', JSON.stringify(currentObj));
    },
    clearCompletedJobs: state => {
      state.completedJobs = [];
    },
    moveToFailedJobs: (state, action) => {
      let filtered = state.polls.filter(i => i.id !== action.payload.id);
      state.polls = filtered;
      state.failedJobs.push(action.payload);

      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.userDataPref.polls = state.polls;
      localStorage.setItem('user', JSON.stringify(currentObj));
    },
    removeFailedJob: (state, action) => {
      let filteredPolls = state.failedJobs.filter(
        poll => poll.id !== action.payload.id
      );
      state.failedJobs = filteredPolls;
    }
  }
});

export const {
  setPolls,
  completeJob,
  clearCompletedJobs,
  moveToFailedJobs,
  removePoll,
  updatePoll,
  removeFailedJob
} = activitySlice.actions;

export default activitySlice.reducer;
