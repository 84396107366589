import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { PlusIcon } from '@radix-ui/react-icons';
import { Avatar, Button, Flex, Text } from '@raystack/apsara';
import { Container, useFrontier } from '@raystack/frontier/react';
import * as _ from 'lodash';
import moment from 'moment';
import useSWR, { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

import AppLoader from '@src/components/AppLoader';
import {
  setActiveAoi,
  setActiveProject
} from '@src/redux/_reducers/projectReducer';
import { styled } from '@src/stitches.config';
import { getInitials } from '@src/utils/StringUtils';
import { acceptOrgInvitation, joinOrg } from '@src/utils/apis/FrontierApis';

import OnboardingLogout from './OnboardingLogout';
import Logo from './logo';
import { Layout, Wrapper } from './styles';

const Box = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 'var(--S)',
  border: '1px solid var(--border-subtle)',
  background: 'var(--background-base)',
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.09)',
  width: '100%',
  minWidth: '500px'
});

const BoxHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: 'var(--gap_5) var(--gap_7)',
  flexWrap: 'wrap',
  borderBottom: '1px solid var(--border-subtle)'
});

const NewOrgLink = styled(Link, {
  display: 'flex',
  justifyContent: 'space-between',
  padding: 'var(--gap_2) var(--gap_3)',
  gap: 'var(--gap_2)',
  alignItems: 'center',
  fontSize: '$1',
  color: 'var(--foreground-base)',
  borderRadius: 'var(--S)',
  border: '1px dashed var(--border-base)',
  background: 'var(--background-base)',
  '&:hover': {
    background: 'var(--background-base-hover)'
  }
});

const BoxBody = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  padding: 'var(--gap_7)',
  gap: 'var(--gap_7)',
  flexWrap: 'nowrap',
  minHeight: '300px',
  maxHeight: '340px',
  overflowX: 'auto'
});

const OrgListItemWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: 'var(--gap_2) var(--gap_3)'
});

const OrgDetails = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 'var(--gap_4)',
  maxWidth: '50%'
});

const OrgAction = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 'var(--gap_3)'
});

function OrgListItem({ org, invitation, isDomainJoin }) {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initials = getInitials(org.title, org.name);

  const isInvitation = !_.isEmpty(invitation);
  const isDisabled = org?.state === 'disabled';

  function openExplore() {
    navigate(`/${org?.name}`);
    dispatch(setActiveProject({}));
    dispatch(setActiveAoi({}));
  }

  const { trigger: acceptInvitation, isMutating: isAcceptLoading } =
    useSWRMutation(
      org?.id && invitation?.id
        ? `/organizations/${org.id}/invitations/${invitation.id}/accept`
        : null,
      () => acceptOrgInvitation(org.id, invitation.id),
      {
        onError: err => {
          console.error(err);
          navigate('/login');
        },
        onSuccess: () => {
          mutate('/self/organizations');
          openExplore();
        }
      }
    );

  const { trigger: joinOrgTrigger, isMutating: isJoinLoading } = useSWRMutation(
    org?.id && isDomainJoin ? `/organizations/${org?.id}/join` : null,
    () => joinOrg(org?.id),
    {
      onError: err => {
        console.error(err);
        navigate('/login');
      },
      onSuccess: () => {
        mutate('/self/organizations');
        openExplore();
      }
    }
  );

  const expiryTime = useMemo(() => {
    const exp = invitation?.expires_at;
    return isInvitation ? moment(exp).fromNow() : null;
  }, [isInvitation, invitation]);

  const isLoading = isAcceptLoading || isJoinLoading;

  return (
    <OrgListItemWrapper>
      <OrgDetails>
        <Avatar
          src={org?.avatar}
          size='extra-large'
          fallback={initials}
          className='!h-[48px] !w-[48px] !text-[20px] text-[var(--foreground-base)]'
        />
        <Text size={4} className='break-words max-w-full text-left'>
          {org?.title || org?.name}
        </Text>
      </OrgDetails>
      <OrgAction>
        {isDisabled ? (
          <>
            <Text size={1} className='!text-[var(--foreground-subtle)]'>
              Pending approval
            </Text>

            <Button variant='secondary' disabled={true}>
              Open
            </Button>
          </>
        ) : isInvitation ? (
          <>
            <Text size={1} className='!text-[var(--foreground-subtle)]'>
              Expires {expiryTime}
            </Text>

            <Button
              variant='primary'
              onClick={acceptInvitation}
              disabled={isLoading}
            >
              {isLoading ? 'Joining...' : 'Join'}
            </Button>
          </>
        ) : isDomainJoin ? (
          <Button
            variant='primary'
            onClick={joinOrgTrigger}
            disabled={isLoading}
          >
            {isLoading ? 'Joining...' : 'Join'}
          </Button>
        ) : (
          <Button
            variant='secondary'
            onClick={openExplore}
            disabled={isLoading}
          >
            Open
          </Button>
        )}
      </OrgAction>
    </OrgListItemWrapper>
  );
}

export default function OrgsListPage() {
  const { user, client } = useFrontier();
  const location = useLocation();
  const { data: orgResp, isLoading: isDomainOrgsLoading } = useSWR(
    user?.id ? `/v1beta1/users/self/organizations` : null,
    () => client.frontierServiceListOrganizationsByCurrentUser(),
    {
      onError: console.error
    }
  );

  const { data: disabledOrgsResp, isLoading: isDisabledOrgsLoading } = useSWR(
    user?.id ? `/v1beta1/users/self/organizations?state=disabled` : null,
    () =>
      client.frontierServiceListOrganizationsByCurrentUser({
        state: 'disabled'
      }),
    {
      onError: console.error
    }
  );

  const { data: invitationsResp, isLoading: isInvitationsLoading } = useSWR(
    user?.email ? '/self/invitations' : null,
    () => client.frontierServiceListCurrentUserInvitations(),
    {
      onError: console.error
    }
  );

  const userOrgs = orgResp?.data?.organizations || [];
  const domainOrgs = orgResp?.data?.joinable_via_domain || [];
  const disabledOrgs = disabledOrgsResp?.data?.organizations || [];

  const isLoading =
    isDomainOrgsLoading || isInvitationsLoading || isDisabledOrgsLoading;

  const orgsWithInvitation = useMemo(() => {
    const invitations = invitationsResp?.data?.invitations || [];
    const orgs = invitationsResp?.data?.orgs || [];

    const orgMap = orgs.reduce((acc, org) => {
      acc[org.id] = org;
      return acc;
    }, {});
    return invitations.map(inv => {
      const org = orgMap[inv.org_id];
      return {
        ...org,
        invitation: inv
      };
    });
  }, [invitationsResp]);

  return isLoading ? (
    <AppLoader />
  ) : (
    <Layout>
      <OnboardingLogout />
      <Wrapper css={{ width: '500px', marginTop: '100px' }}>
        <Container>
          <Flex>
            <Logo />
          </Flex>
          <Flex direction='row' align='center'>
            <Text size={9}>Welcome to Aurora</Text>
          </Flex>
          <Box>
            <BoxHeader>
              <Text size={4}>{user?.email}</Text>
              <NewOrgLink to='/new-organization' state={location.state}>
                <PlusIcon /> <span>Create organization</span>
              </NewOrgLink>
            </BoxHeader>
            <BoxBody>
              {orgsWithInvitation.map(org => {
                return (
                  <OrgListItem
                    org={org}
                    key={org.id}
                    invitation={org.invitation}
                  />
                );
              })}
              {domainOrgs.map(org => {
                return (
                  <OrgListItem org={org} key={org.id} isDomainJoin={true} />
                );
              })}
              {disabledOrgs.map(org => {
                return <OrgListItem org={org} key={org.id} />;
              })}
              {userOrgs.map(org => {
                return <OrgListItem org={org} key={org.id} />;
              })}
            </BoxBody>
          </Box>
        </Container>
      </Wrapper>
    </Layout>
  );
}
