import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

export type RenderFormats = 'webp' | 'png';

interface InfoObject {
  name?: string;
  description?: string;
  loader?: boolean;
}

export interface InitialState {
  showInfoModal: boolean;
  isSidebarOpen: boolean;
  tileRenderFormat: RenderFormats;
  infoModalData: InfoObject;
}

const initialState: InitialState = {
  showInfoModal: false,
  infoModalData: {},
  isSidebarOpen: false,
  tileRenderFormat: 'png'
};

type Reducers = {
  updateTileRenderFormat: CaseReducer<
    InitialState,
    PayloadAction<RenderFormats>
  >;
  setShowInfoModal: CaseReducer<InitialState, PayloadAction<boolean>>;
  setInfoModalData: CaseReducer<InitialState, PayloadAction<InfoObject>>;
  setInfoModalLoader: CaseReducer<InitialState, PayloadAction<boolean>>;
  setIsSidebarOpen: CaseReducer<InitialState, PayloadAction<boolean>>;
};

const sourceActionSlice = createSlice<InitialState, Reducers, 'sourceActions'>({
  name: 'sourceActions',
  initialState,
  reducers: {
    updateTileRenderFormat(state, action) {
      state.tileRenderFormat = action.payload;
    },
    setShowInfoModal: (state, action) => {
      state.showInfoModal = action.payload;
    },
    setInfoModalData: (state, action) => {
      state.infoModalData = action.payload;
    },
    setInfoModalLoader: (state, action) => {
      state.infoModalData.loader = action.payload;
    },
    setIsSidebarOpen: (state, action) => {
      state.isSidebarOpen = action.payload;
    }
  }
});

export const {
  updateTileRenderFormat,
  setShowInfoModal,
  setInfoModalData,
  setInfoModalLoader,
  setIsSidebarOpen
} = sourceActionSlice.actions;

export default sourceActionSlice.reducer;
