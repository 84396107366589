import {
  addOrgIdRequestInterceptor,
  handleResponseStatusInterceptor
} from '@src/api';

import { Api as BeaconApi } from './beacon/api';

const beaconApi = new BeaconApi({
  baseURL: import.meta.env.VITE_BEACON,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

beaconApi.instance.interceptors.request.use(
  addOrgIdRequestInterceptor,
  error => {
    return Promise.reject(error);
  }
);

beaconApi.instance.interceptors.response.use(response => {
  return response;
}, handleResponseStatusInterceptor);

const apiClient = {
  beacon: beaconApi.v1
};

export default apiClient;
