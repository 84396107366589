import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as _ from 'lodash';

import {
  announcementMap,
  resetAnnouncement,
  setAnnouncement
} from '@src/redux/_reducers/appReducer';
import { RootState } from '@src/redux/_reducers/rootReducer';

export default function useAnnouncement() {
  const dispatch = useDispatch();
  const announcement = useSelector(
    (state: RootState) => state.app.announcement
  );
  const isAnnouncementAvailable = !_.isEmpty(announcement);

  const envAnnouncement = import.meta.env.VITE_ANNOUCEMENT;

  useEffect(() => {
    if (envAnnouncement) {
      try {
        const obj = JSON.parse(envAnnouncement);
        obj.type = obj.type || announcementMap.INFO;
        dispatch(setAnnouncement(obj));
      } catch (err) {
        console.warn('unable to parse env announcement');
      }
    }
  }, [dispatch, envAnnouncement]);

  const closeAnnouncement = useCallback(() => {
    dispatch(resetAnnouncement());
  }, [dispatch]);

  return {
    announcement,
    isAnnouncementAvailable,
    closeAnnouncement
  };
}
