import { createStitches } from "@stitches/react";

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      accent: "#03D4FF",
      accent80: "#02AACC",
      accent50: "#027F99",
      accent20: "#01404D",
      accent10: "rgba(3, 212, 255, 0.15)",
      background: "#111213", //---L1 - Main Background
      primary: "#151718", //---L2 - Subtle Background
      tertiary: "#191B1D", //---L3 - UI Element Background
      highlight: "#222527", //--- L5 - UI element Bg [ACTIVE]
      pixxelFont: "#83898D", //--- L11 - Text[Low Contrast]
      pixxelFontBright: "#B8B8B8", //--- not present in Figma
      border: "#404040", //--- not present in Figma
      borderLight: "#272A2D", //-- L6 - Panel borders
      red: "#FF365C",
      green: "#3DE5A8",
      lime: "#B2F047",
      error: "#EC4D2C",
      error10: "rgba(236, 77, 44, 0.1)",
      error20: "rgba(236, 77, 44, 0.2)",
      error50: "rgba(236, 77, 44, 0.5)",
      success: "#39B259",
      success20: "rgba(57, 178, 89, 0.2)",
      warning: "#EE9500",
      warning20: "rgba(238, 149, 0, 0.2)",
      info: "#016EE5",
      info20: "rgba(1, 110, 229, 0.2)",
      defaultTone: "#FFFFFF", //--- L13 - White
    },
    radii: {
      xs: "4px",
      sm: "8px",
      md: "10px",
      lg: "12px",
    },
    fontSizes: {
      1: "12px",
      2: "14px",
      3: "16px",
      4: "18px",
      5: "20px",
      6: "24px",
    },
    space: {
      1: "2px",
      2: "4px",
      3: "8px",
      4: "12px",
      5: "16px",
      6: "20px",
      7: "24px",
      8: '32px',
    },
  },
  media: {
    hd: "(max-width: 1367px)",
    hdplus: "(max-width: 1601px)",
    fourK: "(min-width: 3839px)",
    scale_125: "(-webkit-min-device-pixel-ratio: 1.25)",
    scale_15: "(min-device-pixel-ratio: 1.5)",
  },
  utils: {
    marginX: (value) => ({ marginLeft: value, marginRight: value }),
    marginY: (value) => ({ marginTop: value, marginBottom: value }),
    paddingX: (value) => ({ paddingLeft: value, paddingRight: value }),
    paddingY: (value) => ({ paddingTop: value, paddingBottom: value }),
  },
});

export const lightTheme = createTheme({
  colors: {
    accent: "#03D4FF",
    accent80: "#02AACC",
    accent50: "#027F99",
    accent20: "#01404D",
    accent10: "rgba(3, 212, 255, 0.15)",
    background: "#ECECED", //---L1 - Main Background
    primary: "#E9EAEB", //---L2 - Subtle Background
    tertiary: "#DCDDDE", //---L3 - UI Element Background
    highlight: "#C3C4C5", //--- L5 - UI element Bg [ACTIVE]
    pixxelFont: "#83898D", //--- L11 - Text[Low Contrast]
    pixxelFontBright: "#717679", //--- not present in Figma
    border: "#404040", //--- not present in Figma
    borderLight: "#272A2D", //-- L6 - Panel borders
    red: "#FF365C",
    green: "#3DE5A8",
    lime: "#B2F047",
    error: "#EC4D2C",
    error20: "rgba(236, 77, 44, 0.2)",
    error50: "rgba(236, 77, 44, 0.5)",
    success: "#39B259",
    success20: "rgba(57, 178, 89, 0.2)",
    warning: "#EE9500",
    warning20: "rgba(238, 149, 0, 0.2)",
    info: "#016EE5",
    info20: "rgba(1, 110, 229, 0.2)",
    defaultTone: "#000000", //--- L13 - White
  },
  radii: {
    xs: "4px",
    sm: "8px",
    md: "10px",
    lg: "12px",
  },
  fontSizes: {
    1: "12px",
    2: "14px",
    3: "16px",
    4: "18px",
    5: "20px",
  },
});
