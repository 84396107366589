import { parse } from '@loaders.gl/core';
import type { Loader, LoaderOptions } from '@loaders.gl/loader-utils';

import { DisplayMsg } from '@src/components/PixxelUIKit/Toast';

import { CURRENT_ORG_HEADER } from './constants/APP';

class CustomFetch {
  // eslint-disable-next-line no-use-before-define
  private static instance: CustomFetch;
  private orgId!: string;
  private constructor() {}

  public static getInstance() {
    if (!this.instance) {
      this.instance = new CustomFetch();
    }
    return this.instance;
  }

  public setOrg(orgId: string) {
    this.orgId = orgId;
  }

  public getOrg(): string {
    return this.orgId;
  }

  public fetch(
    input: RequestInfo | URL,
    init: RequestInit = {}
  ): Promise<Response> {
    return fetch(input, {
      ...init,
      headers: {
        ...(init?.headers || {}),
        [CURRENT_ORG_HEADER]: CustomFetch.getInstance().getOrg()
      },
      credentials: 'include'
    });
  }

  public async fetchTile(
    url: string,
    {
      loadOptions,
      signal,
      loaders
    }: {
      loaders?: Loader[];
      loadOptions?: LoaderOptions;
      signal?: AbortSignal;
    }
  ) {
    try {
      const response = await fetch(url, {
        signal,
        headers: {
          [CURRENT_ORG_HEADER]: CustomFetch.getInstance().getOrg()
        },
        credentials: 'include'
      });
      if (!response.ok) {
        if (response.status === 400) {
          DisplayMsg(
            'invalid-expression',
            'warning',
            'Invalid Input!',
            'The input expression is invalid. Please enter a valid expression and try again.'
          );
          return null;
        }
        if (response.status === 404) {
          return null;
        }
        if (response.status === 403) {
          DisplayMsg(
            'un-auth-image',
            'warning',
            'Access Not Available!',
            'Please contact Customer Success team (support@pixxel.space) to get access to this image'
          );
          return null;
        }
      }

      return parse(response, loaders, loadOptions);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}

export const customFetch = CustomFetch.getInstance();
