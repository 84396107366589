import { useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';

import { Flex } from '@raystack/apsara';
import useSWR from 'swr';

import api from '@src/clients';
import { Header } from '@src/components/Custom/Header';
import { useOutsideClick } from '@src/hooks/useOutsideClick';
import { closeNotifications } from '@src/redux/_reducers/appReducer';

import NotificationItem from './item';

import styles from './styles.module.css';

function Loader() {
  return [...new Array(3)].map((_, i) => (
    <Flex
      className={styles.noticationItem}
      gap={'medium'}
      key={i}
      data-loading='true'
    >
      <Skeleton width={'32px'} height={'32px'} />
      <Flex direction={'column'} className={styles.flex1} gap={'small'}>
        <Skeleton containerClassName={styles.flex1} count={2} />
        <Skeleton containerClassName={styles.flex1} width={'50%'} />
      </Flex>
    </Flex>
  ));
}

interface NotificationsProps {
  notificationIconRef: React.MutableRefObject<HTMLElement>;
}

export default function Notifications({
  notificationIconRef
}: NotificationsProps) {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const { data, isLoading } = useSWR('/feed', api.beacon.apiUserSelfFeedList, {
    revalidateOnMount: true,
    refreshInterval: 1000
  });

  const messages = data?.data?.messages || [];

  useOutsideClick({
    triggerRef: notificationIconRef,
    contentRef: ref,
    callback: () => dispatch(closeNotifications())
  });

  return (
    <div className={styles.panel} ref={ref}>
      <Header hasSeparator={false} className={styles.header}>
        <Header.Text size='medium'>All Notifications</Header.Text>
      </Header>

      <Flex direction={'column'}>
        {isLoading ? <Loader /> : null}
        {messages.map(msg => (
          <NotificationItem message={msg} key={msg.message_id} />
        ))}
      </Flex>
    </div>
  );
}
