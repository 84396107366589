import api from "../../api";

export const logout = async () => {
  let response = await api.Frontier.get(`/v1beta1/auth/logout`);
  return response.data;
};

export const fetchOrgInvitation = async (orgId, inviteId) => {
  let response = await api.Frontier.get(
    `/v1beta1/organizations/${orgId}/invitations/${inviteId}`
  );
  return response.data;
};

export const acceptOrgInvitation = async (orgId, inviteId) => {
  let response = await api.Frontier.post(
    `/v1beta1/organizations/${orgId}/invitations/${inviteId}/accept`
  );
  return response.data;
};

export const joinOrg = async (orgId) => {
  let response = await api.Frontier.post(
    `/v1beta1/organizations/${orgId}/join`
  );
  return response.data;
};

export const getOrganization = async (orgId) => {
  let response = await api.Frontier.get(`/v1beta1/organizations/${orgId}`);
  return response.data;
};

export const getCurrentUserOrganizations = async () => {
  let response = await api.Frontier.get(`/v1beta1/users/self/organizations`);
  return response.data;
};

export const getOrganizationUsers = async (orgId) => {
  let response = await api.Frontier.get(
    `/v1beta1/organizations/${orgId}/users`
  );
  return response.data;
};
