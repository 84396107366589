import { styled } from "../../stitches.config";

export const Button = styled("button", {
  transition: "0.2s",
  display: "flex",
  gap: 8,
  variants: {
    type: {
      icon: {
        // container best fit for to house only icons
        padding: "8px",
        borderRadius: "$xs",
      },
      circle: {
        borderRadius: "9999999px",
        paddingX: "8px",
        paddingY: "8px",
      },
      text: {
        // container best fit to only house text/text+icon
        // borderRadius: '$sm',
        borderRadius: "$xs",
        paddingX: "9px",
        paddingY: "7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      blank: {
        // container has nothing
      },
    },
    variant: {
      filled: {
        color: "$defaultTone",
        backgroundColor: "$accent50",
        border: "1px solid $accent50",
        // boxShadow: `0 2px 8px rgba(0, 0, 0, 0.4)`,
        "&:hover": {
          backgroundColor: "$accent80",
          border: "1px solid $accent",
        },
        // "&[disabled]": {
        //   opacity: 0.5,
        // },
      },
      secondary: {
        color: "$defaultTone",
        border: "1px solid $border",
        backgroundColor: "$tertiary",
        "&:hover": {
          backgroundColor: "$highlight",
          color: "$defaultTone",
        },
        // "&[disabled]": {
        //   opacity: 0.5,
        // },
      },
      danger: {
        color: "$defaultTone",
        border: "1px solid $error",
        backgroundColor: "$error20",
        "&:hover": {
          backgroundColor: "$error50",
          color: "$defaultTone",
        },
        // "&[disabled]": {
        //   opacity: 0.5,
        // },
      },
      secondarySvg: {
        stroke: "$defaultTone",
        border: "1px solid $border",
        backgroundColor: "$tertiary",
        "&:hover": {
          backgroundColor: "$highlight",
          stroke: "$defaultTone",
        },
      },
      transparent: {
        backgroundColor: "transparent",
        color: "$defaultTone",
        "&:hover": {
          color: "$accent",
        },
      },
      outline: {},
    },
    selected: {
      true: {
        color: "$accent",
        "&:hover": {
          color: "$accent",
        },
      },
      false: {},
    },
    selectedSvg: {
      true: {
        stroke: "$accent",
        "&:hover": {
          stroke: "$accent",
        },
      },
      false: {},
    },
    size: {
      small: {
        fontSize: "$1",
        paddingX: "8px",
        paddingY: "6px",
      },
      medium: {
        fontSize: "$2",
      },
      large: {
        fontSize: "$3",
      },
    },
    disabled: {
      true: {
        opacity: 0.4,
        pointerEvents: "none",
      },
      false: {},
    },
  },
  defaultVariants: {
    variant: "filled",
    type: "text",
    size: "medium",
  },
});
