/* eslint-disable */

/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from 'axios';
import axios from 'axios';

export interface DomainsCategoryPreference {
  category?: string;
  events?: DomainsEvent[];
}

export interface DomainsChannelDetails {
  name?: string;
  value?: boolean;
}

export interface DomainsCheckSubscription {
  subscribed?: boolean;
}

export interface DomainsEvent {
  channels?: DomainsChannelDetails[];
  name?: string;
}

export interface DomainsFeedItem {
  event?: string;
  message_id?: string;
  message_text?: string;
  org_id?: string;
  status?: string;
  timestamp?: string;
}

export interface DomainsFeedItems {
  messages?: DomainsFeedItem[];
}

export interface DomainsFrontierEvent {
  action?: string;
  data?: {
    metadata?: Record<string, string>;
    target?: {
      id?: string;
      type?: string;
    };
  };
  id?: string;
}

export interface DomainsFrontierUserCreationEvent {
  action?: string;
  data?: {
    metadata?: {
      email?: string;
      name?: string;
    };
    target?: {
      id?: string;
      type?: string;
    };
  };
}

export interface DomainsNotification {
  actor?: string;
  data?: Record<string, any>;
  event?: string;
  org_id?: string;
  resource_id?: string;
  resource_type?: string;
  skip_notifying_actor?: boolean;
}

export interface DomainsSubscription {
  resource_id?: string;
  resource_type?: string;
  user_id?: string;
}

export interface DomainsUser {
  email?: string;
  id?: string;
  name?: string;
}

export interface DomainsUserPreferences {
  preferences?: DomainsCategoryPreference[];
  user_id?: string;
}

export interface ErrorsApiError {
  error?: ErrorsError;
}

export interface ErrorsError {
  code?: string;
  details?: Record<string, string>;
  message?: string;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || ''
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    });
  };
}

/**
 * @title Beacon Service API Doc
 * @version 0.1
 * @contact Abhishek Sah <abhisheksah@pixxel.co.in>
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  ping = {
    /**
     * No description
     *
     * @tags external
     * @name PingList
     * @summary Ping Server
     * @request GET:/ping
     */
    pingList: (params: RequestParams = {}) =>
      this.request<Record<string, string>, any>({
        path: `/ping`,
        method: 'GET',
        format: 'json',
        ...params
      })
  };
  v1 = {
    /**
     * No description
     *
     * @tags external
     * @name ApiUserSelfFeedList
     * @summary Get In-app feed for a user
     * @request GET:/v1/api/user/self/feed
     */
    apiUserSelfFeedList: (params: RequestParams = {}) =>
      this.request<DomainsFeedItems, ErrorsApiError>({
        path: `/v1/api/user/self/feed`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags external
     * @name ApiUserSelfPreferencesList
     * @summary Get notification preferences for a user
     * @request GET:/v1/api/user/self/preferences
     */
    apiUserSelfPreferencesList: (params: RequestParams = {}) =>
      this.request<DomainsUserPreferences, ErrorsApiError>({
        path: `/v1/api/user/self/preferences`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags external
     * @name ApiUserSelfPreferencesUpdate
     * @summary Set notification preferences for a user
     * @request PUT:/v1/api/user/self/preferences
     */
    apiUserSelfPreferencesUpdate: (
      req: DomainsUserPreferences,
      params: RequestParams = {}
    ) =>
      this.request<void, ErrorsApiError>({
        path: `/v1/api/user/self/preferences`,
        method: 'PUT',
        body: req,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags external
     * @name ApiUserSelfReadCreate
     * @summary Mark an in-app message read
     * @request POST:/v1/api/user/self/read/{message_id}
     */
    apiUserSelfReadCreate: (messageId: string, params: RequestParams = {}) =>
      this.request<void, ErrorsApiError>({
        path: `/v1/api/user/self/read/${messageId}`,
        method: 'POST',
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags external
     * @name ApiUserSelfSubscriptionDetail
     * @summary Check if a user is subscribed to a resource
     * @request GET:/v1/api/user/self/subscription/{resource_type}/{resource_id}
     */
    apiUserSelfSubscriptionDetail: (
      resourceType: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<DomainsCheckSubscription, ErrorsApiError>({
        path: `/v1/api/user/self/subscription/${resourceType}/${resourceId}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags external
     * @name ApiUserSelfSubscriptionDelete
     * @summary Remove subscription of a user from a resource
     * @request DELETE:/v1/api/user/self/subscription/{resource_type}/{resource_id}
     */
    apiUserSelfSubscriptionDelete: (
      resourceType: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, ErrorsApiError>({
        path: `/v1/api/user/self/subscription/${resourceType}/${resourceId}`,
        method: 'DELETE',
        ...params
      }),

    /**
     * No description
     *
     * @tags external
     * @name ApiUserSelfSubscriptionCreate
     * @summary Subscribe any user to a resource NOT created by him
     * @request POST:/v1/api/user/self/subscription/{resource_type}/{resource_id}/
     */
    apiUserSelfSubscriptionCreate: (
      resourceType: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, ErrorsApiError>({
        path: `/v1/api/user/self/subscription/${resourceType}/${resourceId}/`,
        method: 'POST',
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags internal
     * @name InternalNotificationCreate
     * @summary Send notification to user
     * @request POST:/v1/internal/notification
     */
    internalNotificationCreate: (
      req: DomainsNotification,
      params: RequestParams = {}
    ) =>
      this.request<void, ErrorsApiError>({
        path: `/v1/internal/notification`,
        method: 'POST',
        body: req,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags internal
     * @name InternalResetSubscriptionsCreate
     * @summary Reset subscriptions of a user
     * @request POST:/v1/internal/reset_subscriptions
     */
    internalResetSubscriptionsCreate: (
      req: DomainsFrontierEvent,
      params: RequestParams = {}
    ) =>
      this.request<void, ErrorsApiError>({
        path: `/v1/internal/reset_subscriptions`,
        method: 'POST',
        body: req,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags internal
     * @name InternalUserUpdate
     * @summary Create or update a user on Knock
     * @request PUT:/v1/internal/user
     */
    internalUserUpdate: (req: DomainsUser, params: RequestParams = {}) =>
      this.request<DomainsUser, ErrorsApiError>({
        path: `/v1/internal/user`,
        method: 'PUT',
        body: req,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags internal
     * @name InternalUserCreate
     * @summary Create or update a user - To be called by Frontier webhook
     * @request POST:/v1/internal/user
     */
    internalUserCreate: (
      req: DomainsFrontierUserCreationEvent,
      params: RequestParams = {}
    ) =>
      this.request<DomainsUser, ErrorsApiError>({
        path: `/v1/internal/user`,
        method: 'POST',
        body: req,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags internal
     * @name InternalUserSubscribeCreate
     * @summary Subscribe any user to a resource created by him
     * @request POST:/v1/internal/user/{id}/subscribe
     */
    internalUserSubscribeCreate: (
      id: string,
      req: DomainsSubscription,
      params: RequestParams = {}
    ) =>
      this.request<void, ErrorsApiError>({
        path: `/v1/internal/user/${id}/subscribe`,
        method: 'POST',
        body: req,
        type: ContentType.Json,
        ...params
      })
  };
}
