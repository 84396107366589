import React from "react";
import { styled, keyframes } from "../../stitches.config";
import { blackA } from "@radix-ui/colors";
import { Cross2Icon } from "@radix-ui/react-icons";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Button } from "./Button";
import { Title } from "./Typography";

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: blackA.blackA11,
  position: "fixed",
  inset: 0,
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

const StyledContent = styled(DialogPrimitive.Content, {
  backgroundColor: "$tertiary",
  borderRadius: "$sm",
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "1px solid $border",
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${contentShow} 200ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  "&:focus": { outline: "none" },
});

function Content({ children, ...props }) {
  return (
    <DialogPrimitive.Portal>
      <StyledOverlay
        css={{ zIndex: 999 }}
        onClick={() => props.setopen(false)}
      />
      <StyledContent {...props} css={{ zIndex: 9991 }}>
        {children}
      </StyledContent>
    </DialogPrimitive.Portal>
  );
}

const StyledTitle = styled(DialogPrimitive.Title, {
  margin: 0,
  borderBottom: "1px solid $border",
  paddingY: "12px",
  paddingX: "10px",
});

const StyledDescription = styled(DialogPrimitive.Description, {
  margin: "8px 0 12px",
  lineHeight: 1,
});

// Exports
export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogContent = Content;
export const DialogTitle = StyledTitle;
export const DialogDescription = StyledDescription;
export const DialogClose = DialogPrimitive.Close;
const Box = styled("div");
export const Footer = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  // marginTop: "24px",
  borderTop: "1px solid $border",
  padding: "8px",
});
export const Body = styled("div", {
  minWidth: "50vh",
  minHeight: "20vh",
  paddingX: "10px",
  paddingTop: "10px",
});

const Modal = (props) => {
  return (
    <Dialog open={props.open}>
      <DialogContent setopen={props.setOpen}>
        <DialogTitle>
          <Title level="06" type="primary">
            {props.title}
          </Title>
        </DialogTitle>
        <Body>{props.children}</Body>

        <Footer>
          {props.isCancel && (
            <DialogClose asChild>
              <Button
                variant="secondary"
                type="text"
                onClick={() => {
                  props.setOpen(false);
                  props.onClose();
                }}
              >
                Close
              </Button>
            </DialogClose>
          )}
          {props.actionButton}
        </Footer>
        <DialogClose asChild>
          <Button
            type="blank"
            variant="transparent"
            size="small"
            css={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 10,
              right: 10,
            }}
            onClick={() => {
              props.setOpen(false);
              props.onClose();
            }}
          >
            <Cross2Icon />
          </Button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};

Modal.defaultProps = {
  isCancel: true,
};

export default Modal;
