export const DEFAULT_CURRENCY = 'USD';
export const DEFAULT_THEME = import.meta.env.VITE_DEFAULT_THEME || 'system';

export const EXPLORE_FILTER_START_YEAR = new Date(2013, 0);

export const CURRENT_ORG_HEADER = 'X-Organization-Id';

export const PaymentCheckoutStateMap = {
  COMPLETE: 'complete'
};

export const SubscriptionStateMap = {
  ACTIVE: 'active',
  PENDING: 'pending',
  PAST_DUE: 'past_due',
  TRIALING: 'trialing'
};

export const IAMDialogTypePathMap = {
  plans: '/plans',
  tokens: '/tokens',
  billing: '/billing'
};

export const IAMCheckoutTypesMap = {
  PLANS: 'plans',
  TOKENS: 'tokens',
  BILLING: 'billing'
};

export const OrganizationStateMap = {
  ENABLED: 'enabled'
};

export const RegexTest4XX = /4\d{2}/;
export const RegexTest5XX = /^5[0-9]{2}$/;

export const EMAILS = {
  BILLING: 'support@pixxel.space'
};

// taken from https://github.com/DamonOehlman/detect-browser/blob/546e6f1348375d8a486f21da07b20717267f6c49/src/index.ts#L92
export const SAFARI_COMPATIBILITY_LIST = ['safari', 'ios-webview'];

export const ENTITLEMENT_MSGS = {
  entitlementFailed: 'entitlement_failed',
  insufficientCredit: 'insufficent_credit'
};

export const PLAN_DOWNGRADE_MESSAGES = {
  enterprise_to_standard:
    'Downgrading your plan to Standard Plan will block all analytics and workflow capabilities.',
  enterprise_to_professional:
    'Downgrading your plan to Professional Plan will block certain features which are available in Enterprise Plan.',
  professional_to_standard:
    'Downgrading your plan to Standard Plan will block all analytics and workflow capabilities.'
};

// visualisation contants
export const COLORMAP_TYPES = {
  discreteColormap: 'discrete',
  rangeColormap: 'continuous',
  bucketColormap: 'bucket'
};

export const RESAMPLING_METHODS = {
  nearestResampling: 'nearest'
};

export const QS_RENDER_OPTIONS = {
  sort: false,
  strict: false,
  encode: false
};

export const ORGANIZATION_TYPES = [
  'Agriculture',
  'Forestry',
  'Climate',
  'Environment and Biodiversity',
  'Mineralogy and Mining',
  'Water Quality',
  'Energy',
  'Urban Monitoring',
  'Other'
];

export const LAST_ORGANIZATION_KEY_NAME = 'LAST_ORGANIZATION';
