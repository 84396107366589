import React, { useEffect, useState, useMemo } from "react";
import { Navigate, useMatch, useParams } from "react-router";
import * as _ from "lodash";
import { useFrontier } from "@raystack/frontier/react";
import AppLoader from "./AppLoader";
import PendingOrgPage from "@src/containers/Auth/PendingOrg";
import useSWR from "swr";

export const OnboardingRoute = ({
  component: Component = () => <div />,
  ...rest
}) => {
  const { client, user } = useFrontier();
  const { orgId } = useParams("/organizations/:orgId");

  const isOrgListRoute = useMatch("/organizations");
  const isNewOrgRoute = useMatch("/new-organization");

  const [domainOrgs, setDomainOrgs] = useState([]);
  const [userOrgs, setUserOrgs] = useState([]);

  const [isDomainOrgsLoading, setIsDomainOrgsLoading] = useState(false);

  async function getDomainOrgs() {
    try {
      setIsDomainOrgsLoading(true);
      const { data } =
        await client.frontierServiceListOrganizationsByCurrentUser();
      setUserOrgs(data.organizations);
      setDomainOrgs(data.joinable_via_domain);
    } catch (err) {
      console.error(err);
    } finally {
      setIsDomainOrgsLoading(false);
    }
  }

  const { data: invitationsResp, isLoading: isInvitationsLoading } = useSWR(
    user?.email ? "/self/invitations" : null,
    () => client.frontierServiceListCurrentUserInvitations(),
    {
      onError: console.error,
    }
  );

  const { data: orgResp, isLoading: isOrgLoading } = useSWR(
    orgId && !isOrgListRoute && !isNewOrgRoute
      ? `/organizations/${orgId}`
      : null,
    () => client.frontierServiceGetOrganization(orgId),
    {
      onError: console.error,
    }
  );

  useEffect(() => {
    if (user?.email) {
      getDomainOrgs();
    }
  }, [user?.email]);

  const org = orgResp?.data?.organization;

  const invitations = useMemo(() => {
    const userOrgIds = userOrgs.map(({ id }) => id);
    // remove invitation is user already joined the org. There is small delay after accpeting invite to expire invite
    return invitationsResp?.data?.invitations.filter(
      ({ org_id }) => !userOrgIds.includes(org_id)
    );
  }, [invitationsResp?.data?.invitations, userOrgs]);

  const isLoading = isDomainOrgsLoading || isInvitationsLoading || isOrgLoading;

  const isOrgEnabled = useMemo(() => {
    return org?.state === "enabled";
  }, [org?.state]);

  return _.isEmpty(user) ? (
    <Navigate replace to="/login" />
  ) : isLoading ? (
    <AppLoader />
  ) : (invitations.length > 0 || domainOrgs.length) &&
    !isOrgListRoute &&
    !isNewOrgRoute ? (
    <Navigate replace to={`/organizations`} />
  ) : !isOrgEnabled && !_.isEmpty(org) ? (
    <PendingOrgPage organization={org} />
  ) : (
    <Component {...rest} />
  );
};
