export const SUPPORTED_COLLTECION_CONSTS = {
  SENTINEL: "sentinel-2-l2a",
  D2: "pixxel-td1-l2a",
  SHAKUNTALLA: "pixxel-td2-l2a",
};

export const CONSTELLATION_IDS_CONSTS = {
  SENTINEL_2: "Sentinel-2",
  PIXXEL_D2: "Pixxel-D2",
  SHAKUNTALA: "Shakuntala",
};

export const CONSTELLATION_CONTROL = [
  CONSTELLATION_IDS_CONSTS.SENTINEL_2,
  CONSTELLATION_IDS_CONSTS.PIXXEL_D2,
  CONSTELLATION_IDS_CONSTS.SHAKUNTALA,
];

export const COLLECTION_MAP = {
  [CONSTELLATION_IDS_CONSTS.SENTINEL_2]: SUPPORTED_COLLTECION_CONSTS.SENTINEL,
  [CONSTELLATION_IDS_CONSTS.PIXXEL_D2]: SUPPORTED_COLLTECION_CONSTS.D2,
  [CONSTELLATION_IDS_CONSTS.SHAKUNTALA]:
    SUPPORTED_COLLTECION_CONSTS.SHAKUNTALLA,
};
