import Skeleton from 'react-loading-skeleton';

import { Flex } from '@raystack/apsara';

import classes from './Skeleton.module.css';
import 'react-loading-skeleton/dist/skeleton.css';

export const Line = () => {
  return (
    <Skeleton
      count={1}
      height={16}
      containerClassName={classes.lineContainer}
    />
  );
};

export const TileList = () => {
  return (
    <Flex gap='small'>
      <Skeleton width={'176px'} height={'176px'} />
      <Flex direction='column'>
        <Skeleton count={3} />
        <Skeleton width='200px' />
        <Skeleton width='200px' />
      </Flex>
    </Flex>
  );
};

export const ImageList = () => {
  return (
    <div className='w-full h-full flex'>
      <Skeleton
        width={'18vh'}
        height={'18vh'}
        style={{
          top: '-2px'
        }}
      />
      <div className='p-2 w-full h-full'>
        <Skeleton count={3} />
        <Skeleton width='8vw' />
        <Skeleton width='6vw' />
      </div>
    </div>
  );
};

export const ModelList = () => {
  return (
    <div className='w-full h-full flex'>
      <Skeleton
        width={'20vh'}
        height={'20vh'}
        style={{
          top: '-2px'
        }}
      />
      <div className='p-2 w-full h-full'>
        <Skeleton width='24vw' />
        {[...Array(2)].map(n => (
          <Skeleton width='20vw' key={n} />
        ))}
        <Skeleton width='10vw' />
      </div>
    </div>
  );
};

export const SatelliteList = () => {
  return (
    <div className='w-full h-full flex flex-col'>
      <Skeleton count={5} />
    </div>
  );
};

export const AoiList = ({ count }) => {
  return (
    <div className='w-full h-full flex flex-col gap-[8px]'>
      {[...Array(count)].map((i, idx) => (
        <div className='w-full' key={idx}>
          <Skeleton count={2} />
        </div>
      ))}
    </div>
  );
};

export const LayerSkeleton = () => {
  return (
    <div className='flex gap-[8px]'>
      {[...Array(3)].map(i => (
        <Skeleton count={1} height={84} width={84} key={i} />
      ))}
    </div>
  );
};

export const AssetList = ({ count }) => {
  return (
    <div className='w-full flex flex-col gap-[10px]'>
      {[...Array(count)].map((_, i) => (
        <div className='flex gap-[10px]' key={i}>
          <Skeleton circle={true} count={1} width={42} height={42} />
          <Skeleton count={2} width={200} />
        </div>
      ))}
    </div>
  );
};

export const List = ({ count }) => {
  return (
    <div className='w-full flex flex-col gap-[10px]'>
      {[...Array(count)].map(i => (
        <div className='flex gap-[10px]' key={i}>
          <Skeleton circle={true} count={1} width={36} height={36} />
          <div className='w-full flex flex-col my-auto'>
            <Skeleton count={1} />
          </div>
        </div>
      ))}
    </div>
  );
};

export const MdFile = () => {
  return (
    <div>
      {/* Title */}
      <Skeleton count={1} height={30} />

      {/* About section */}
      <div>
        <Skeleton count={1} height={20} />
        <Skeleton count={1} height={20} />
        <Skeleton count={1} height={20} />
      </div>

      {/* Input Bands section */}
      <div>
        <Skeleton count={1} height={20} />
        {/* Table header */}
        <div style={{ display: 'flex', gap: '10px' }}>
          <Skeleton count={1} width={100} />
          <Skeleton count={1} width={200} />
        </div>

        {/* Table rows */}
        {[...Array(5)].map((i, index) => (
          <div key={index} style={{ display: 'flex', gap: '10px' }}>
            <Skeleton count={1} width={100} />
            <Skeleton count={1} width={200} />
          </div>
        ))}
      </div>

      <div>
        <Skeleton count={1} height={20} />
        {/* List items */}
        {[...Array(4)].map((i, index) => (
          <div key={index}>
            <Skeleton count={1} height={20} />
          </div>
        ))}
      </div>
    </div>
  );
};

export const InputOutputLoader = () => {
  return (
    <div>
      <div>
        <Skeleton height={20} width={100} />
      </div>
      <div>
        <Skeleton height={20} />
        <Skeleton height={30} />
      </div>
      <div>
        <Skeleton height={20} />
        <Skeleton height={30} />
      </div>
    </div>
  );
};
