import D2Data from "@src/utils/helpers/cosmosD2.json";
import ShakData from "@src/utils/helpers/cosmosShakuntala.json";

import {
  CONSTELLATION_IDS_CONSTS,
  SUPPORTED_COLLTECION_CONSTS,
} from "./LENS_CONSTANTS";

export const SATELLITE_LISTS = [
  {
    satellite_id: CONSTELLATION_IDS_CONSTS.PIXXEL_D2,
    collection_id: SUPPORTED_COLLTECION_CONSTS.D2,
    label: CONSTELLATION_IDS_CONSTS.PIXXEL_D2,
    data: D2Data,
  },
  {
    satellite_id: CONSTELLATION_IDS_CONSTS.SHAKUNTALA,
    collection_id: SUPPORTED_COLLTECION_CONSTS.SHAKUNTALLA,
    label: CONSTELLATION_IDS_CONSTS.SHAKUNTALA,
    data: ShakData,
  },
];
