import { useCallback, useContext } from 'react';

import { FlagsContext } from './FlagsProvider';
import { FLAGS_KEYS } from './flags';

export const useFlags = () => {
  const { flags } = useContext(FlagsContext);

  const isEnable = useCallback(
    (key: FLAGS_KEYS) => {
      return flags ? Boolean(flags[key]) : false;
    },
    [flags]
  );

  return { isEnable, flags };
};
