import { V1Beta1User } from '@raystack/frontier';
import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IAMDialogState {
  isOpen: boolean;
  route: string;
}

export interface InitialState {
  activeUser?: V1Beta1User;
  IAMDialogState: IAMDialogState;
}

const initialState: InitialState = {
  activeUser: {},
  IAMDialogState: {
    isOpen: false,
    route: '/'
  }
};

function initLocalStorageUser() {
  const user = localStorage.getItem('user');
  const currentObj = user ? JSON.parse(user) : {};
  // TODO: need to check if current user is part of activeOrg and activeProject
  const { activeOrg = {}, activeProject = {}, activeAoi = {} } = currentObj;

  localStorage.setItem(
    'user',
    JSON.stringify({
      activeOrg,
      activeProject,
      activeAoi,
      activeWorkflow: {},
      activeJob: {},
      filterPreferences: {
        showAreaCoverage: false,
        showCloudCoverage: false,
        showGSD: false,
        showAngle: false
      },
      filterValues: {
        areaCoverage: [0, 100],
        cloudCoverage: [0, 100],
        spatialResolution: [0, 500],
        offNadirAngle: [0, 100]
      },
      keyboardShortcuts: {
        // enableAoiList: true,
        // enableProjectList: true,
        // enableAoiDraw: true,
      },
      userDataPref: {
        spectralInfo: [],
        polls: []
      }
    })
  );
}

type Reducers = {
  logoutUser: CaseReducer<InitialState>;
  setActiveUser: CaseReducer<InitialState, PayloadAction<V1Beta1User>>;
  setIAMDialogState: CaseReducer<InitialState, PayloadAction<IAMDialogState>>;
};

export const authSlice = createSlice<InitialState, Reducers, 'auth'>({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: state => {
      localStorage.removeItem('user');
      localStorage.removeItem('searchHistory');
      state.activeUser = {};
    },
    setActiveUser: (state, action) => {
      state.activeUser = action.payload;
      initLocalStorageUser();
    },
    setIAMDialogState: (state, action) => {
      state.IAMDialogState = action.payload;
    }
  }
});

export const { logoutUser, setActiveUser, setIAMDialogState } =
  authSlice.actions;

export default authSlice.reducer;
