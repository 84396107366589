import { useEffect } from 'react';

export const useClearLocalStorage = () => {
  useEffect(() => {
    const key = 'cleared-local-storage-v4';
    const isClearedLocalStorage = localStorage.getItem(key);

    if (isClearedLocalStorage === null) {
      localStorage.clear();
      localStorage.setItem(key, 'true');
      window.location.reload();
    }
  }, []);

  return null;
};
