import { createContext, useState } from 'react';

import _ from 'lodash';

import { fetchSpectralData } from '@src/utils/SpectralUtils';

// const COLORSCALE_DEFAULT = { rescale: [-1, 1], colormap: "spectral", type: 'range', buckets: {}, title: "" };

export const ViewerContext = createContext({
  previewUrl: {},
  colorScale: {
    // ...COLORSCALE_DEFAULT,
  },
  selectedScene: {},
  clearColorScaleAndPreviewUrl: () => {}
});

export const ViewerProvider = ({ children }) => {
  const [previewUrl, setPreviewUrl] = useState({});
  const [colorScale, setColorScale] = useState({});
  const [selectedScene, setSelectedScene] = useState({});
  const [points, setPoints] = useState([]);

  // useEffect(() => console.log("previewURL", previewUrl), [previewUrl]);
  // useEffect(() => console.log("colorscale", colorScale), [colorScale]);

  /**
   *
   * @param {String} url - TiTiler URL
   * @param {Boolean} isMosaic - OPTIONAL for frontend mosaicing
   */
  const setPreviewURL = (url, isMosaic = false) => {
    const previewObj = {
      url: url,
      isMosaic: isMosaic
    };
    if (url === '') {
      setPreviewUrl({});
      return;
    }
    setPreviewUrl(previewObj);
    return;
  };
  const updateColorScale = colorscale => {
    setColorScale(colorscale);
  };

  const updateSelectedScene = scene => {
    setSelectedScene(scene);
  };

  const clearColorScaleAndPreviewUrl = () => {
    setPreviewURL('');
    setColorScale({});
  };

  const updatePoints = (spectralPoint, isCallAPI, setSpectralLoader) => {
    const existingPointIndex = points.findIndex(
      point => point.id === spectralPoint.id
    );

    if (existingPointIndex !== -1) {
      const existingPoint = points[existingPointIndex];

      if (
        !_.isEqual(existingPoint.coordinates, spectralPoint.coordinates) ||
        isCallAPI
      ) {
        const updatedPoints = [...points];
        updatedPoints[existingPointIndex] = {
          ...existingPoint,
          coordinates: spectralPoint.coordinates
        };
        setPoints(updatedPoints);

        if (isCallAPI) {
          fetchSpectralData(
            existingPoint,
            updatedPoints,
            setPoints,
            setSpectralLoader || (() => {})
          );
        }
      }
    } else {
      const updatedPoints = [...points, spectralPoint].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setPoints(updatedPoints);

      if (isCallAPI) {
        fetchSpectralData(
          spectralPoint,
          updatedPoints,
          setPoints,
          setSpectralLoader || (() => {})
        );
      }
    }
  };

  const removePoint = spectralPoint => {
    const updatedPoints = points.filter(point => point.id !== spectralPoint.id);
    return setPoints(updatedPoints);
  };

  return (
    <ViewerContext.Provider
      value={{
        previewUrl,
        colorScale,
        selectedScene,
        setPreviewURL,
        updateColorScale,
        updateSelectedScene,
        clearColorScaleAndPreviewUrl,
        points,
        updatePoints,
        removePoint
      }}
    >
      {children}
    </ViewerContext.Provider>
  );
};
