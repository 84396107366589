import { createContext } from 'react';

import { V1Beta1Organization, V1Beta1User } from '@raystack/frontier';

interface IdentityContextType {
  activeOrg?: V1Beta1Organization;
  user?: V1Beta1User;
  organizations?: V1Beta1Organization[];
}

export const IdentityContext = createContext<IdentityContextType>({
  activeOrg: {},
  user: {},
  organizations: []
});
