import { logoutUser } from "../redux/_reducers/authReducer";
import store from "../redux/store";
import * as Sentry from "@sentry/browser";

import { logout } from "./apis/FrontierApis";

export async function LogoutUser(navigate = null) {
  await logout();
  store.dispatch(logoutUser());
  const currentPath = window.location.pathname;
  Sentry.setUser(null);
  if (navigate !== null) {
    navigate("/login");
  } else if (!["/login", "/signup"].includes(currentPath)) {
    window.location.href = "/login";
    window.location.reload();
  }
}

// export const refreshToken = async () => {
//   const refresh = JSON.parse(localStorage.getItem("user"))?.value?.refresh;
//   try {
//     const result = await api.auth.post("/token/refresh/", { refresh: refresh });
//     return result.data;
//   } catch (error) {
//     console.error(error);
//     LogoutUser();
//   }
// };
