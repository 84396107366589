import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blocks: [],
  editInput: false,
  deleteBlock: false,
  selectedBlock: {},
};

export const blockSlice = createSlice({
  name: "blocks",
  initialState,
  reducers: {
    setblocks: (state, action) => {
      state.blocks = action.payload;
    },
    setEditInput: (state, action) => {
      state.editInput = action.payload;
    },
    setSelectedBlock: (state, action) => {
      state.selectedBlock = action.payload;
    },
    setDeleteBlock: (state, action) => {
      state.deleteBlock = action.payload;
    },
  },
});

export const {
  setblocks,
  setEditInput,
  setSelectedBlock,
  setDeleteBlock,
} = blockSlice.actions;

export default blockSlice.reducer;
