import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';

import { useFrontier } from '@raystack/frontier/react';
import * as _ from 'lodash';

import { setActiveUser } from '@src/redux/_reducers/authReducer';
import { getOrgSlugToNavigate } from '@src/utils/auth';

import AppLoader from './AppLoader';

export const LoginRoute = ({ component: Component, ...rest }) => {
  const { client } = useFrontier();
  const [userOrgs, setUserOrgs] = useState([]);
  const [userOrgsLoader, setUserOrgsLoader] = useState(false);
  const [userLoader, setUserLoader] = useState(true);
  const user = useSelector(state => state.auth.activeUser);
  const dispatch = useDispatch();

  const isUserEmpty = useMemo(() => _.isEmpty(user), [user]);

  const fetchAndSetUserOrgs = useCallback(async () => {
    try {
      setUserOrgsLoader(true);
      const resp =
        await client?.frontierServiceListOrganizationsByCurrentUser();
      if (resp?.data?.organizations) {
        setUserOrgs(resp?.data?.organizations);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setUserOrgsLoader(false);
    }
  }, [client]);

  const loadCurrentUser = useCallback(async () => {
    try {
      setUserLoader(true);
      const userResp = await client.frontierServiceGetCurrentUser();
      if (userResp?.data) {
        dispatch(setActiveUser(userResp?.data?.user));
        fetchAndSetUserOrgs();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setUserLoader(false);
    }
  }, [client]);

  useEffect(() => {
    if (isUserEmpty) {
      loadCurrentUser();
    } else {
      setUserLoader(false);
    }
  }, [loadCurrentUser, isUserEmpty]);

  const orgSlug = getOrgSlugToNavigate(userOrgs);

  return userLoader || userOrgsLoader ? (
    <AppLoader />
  ) : _.isEmpty(user) ? (
    <Component {...rest} />
  ) : userOrgs.length < 1 ? (
    <Navigate replace to='/organizations' />
  ) : (
    <Navigate replace to={`/${orgSlug}`} />
  );
};
