import { toast } from '@raystack/apsara';

export const DisplayMsg = (
  id,
  toastStatus,
  toastContent,
  toastBody,
  actionBtn = null
) => {
  let toastContainer;

  switch (toastStatus) {
    case 'loading':
      toastContainer = toast.loading;
      break;

    case 'success':
      toastContainer = toast.success;
      break;

    case 'warning':
      toastContainer = toast.warning;
      break;

    case 'error':
      toastContainer = toast.error;
      break;

    case 'info':
      toastContainer = toast.info;
      break;

    default:
      toastContainer = toast;
  }

  return toastContainer(toastContent, {
    action: actionBtn,
    invert: true,
    dismissible: true,
    description: toastBody,
    id
  });
};
