import { useEffect } from 'react';

interface Show {
  when: boolean;
  children: React.ReactNode;
  fallback?: React.ReactNode | null;
  sad?: VoidFunction;
}

export const Show = ({ when, children, fallback = null, sad }: Show) => {
  useEffect(() => {
    if (typeof sad === 'function' && !when) sad();
  }, [when, sad]);

  return when ? children : fallback;
};
