import _ from 'lodash';
import moment from 'moment';

import { DisplayMsg } from '@src/components/PixxelUIKit/Toast';
import {
  setActiveAoi,
  setActiveProject
} from '@src/redux/_reducers/projectReducer';
import store from '@src/redux/store';

// Handle Projects on init load
export const _initProjectLoad = (projects = [], activeOrg) => {
  const state = store.getState();
  const activeProject = state.projects.activeProject;

  // Add extra check as swr returns cache data
  const projectList = projects.filter(proj => proj.org_id === activeOrg.id);

  const isActiveProjectDifferent =
    _.isEmpty(activeProject) ||
    !projectList.find(project => project.id === activeProject.id);

  // sometimes user deletes the project and it is not reflected in the state
  if (isActiveProjectDifferent) {
    const newProject = projectList.length ? projectList[0] : {};
    store.dispatch(setActiveProject(newProject));
    store.dispatch(setActiveAoi({}));
  }

  return [...projects].sort(
    (a, b) => new moment.utc(b.created_at) - new moment.utc(a.created_at)
  );
};

// global project error handler
export const ProjectErrorHelper = error => {
  if (error?.response?.status == 400) {
    console.log(error.response.data);
    DisplayMsg(
      'fetch-project-400',
      'error',
      'Something went wrong!',
      `${error.response.data.detail}`
    );
    return;
  }
  if (error?.response?.status == 404) {
    // store.dispatch(setRecentProjects([]));
    store.dispatch(setActiveProject({}));
    store.dispatch(setActiveAoi({}));
    return;
  }
  DisplayMsg(
    'fetch-project-failed',
    'error',
    'Oops! Something went wrong!',
    'We experienced an unfortunate issue with our backend services while fetching projects, please try again after sometime!'
  );
  console.log(error);
  return;
};
