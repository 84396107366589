import { Component, ErrorInfo, ReactNode, useCallback, useEffect } from 'react';

import { LinkBreak2Icon, ReloadIcon } from '@radix-ui/react-icons';
import { Button, Flex, Text } from '@raystack/apsara';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';

import { EmptyState } from '@src/components/EmptyState';
import { Show } from '@src/hoc';

const InternalError = ({ isRoot = false }) => {
  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    addEventListener('popstate', handleReload);

    return () => {
      removeEventListener('popstate', handleReload);
    };
  }, [handleReload]);

  return (
    <div className={clsx(isRoot && 'w-screen h-screen')}>
      <EmptyState
        icon={<LinkBreak2Icon />}
        headerText='Something Gone Wrong'
        subHeaderText='System is facing some issue with your request, please try again later.'
        primaryButton={
          <Button
            data-test-id='something-gone-wrong-btn'
            variant='primary'
            size='medium'
            onClick={handleReload}
          >
            <Flex direction='row' gap='small'>
              <ReloadIcon />
              <Text size={2} style={{ color: 'inherit' }}>
                Reload Now
              </Text>
            </Flex>
          </Button>
        }
      />
    </div>
  );
};

interface Props {
  children?: ReactNode;
  isRoot?: boolean;
}

interface State {
  hasError: boolean;
  eventId?: string;
  errorInfo?: ErrorInfo;
}

const ERROR_MESSAGE = 'dynamically imported module';

function checkDynamicalImportError(message: string) {
  return !!message?.includes(ERROR_MESSAGE);
}

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(props: { message: string }) {
    // Update state so the next render will show the fallback UI.
    if (!checkDynamicalImportError(props.message)) {
      return { hasError: true };
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error({ error, errorInfo });

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo as Record<string, unknown>);
      const eventId = Sentry.captureException(error);

      if (checkDynamicalImportError(error.message)) {
        window.location.reload();
      } else {
        this.setState({ eventId, errorInfo });
      }
    });
  }

  render() {
    return (
      <Show
        when={!this.state.hasError}
        fallback={<InternalError isRoot={this.props.isRoot} />}
      >
        {this.props.children}
      </Show>
    );
  }
}
