import React from "react";
import Modal from "../PixxelUIKit/Modal";
import { Text, Title } from "../PixxelUIKit/Typography";
import {
  setInfoModalData,
  setShowInfoModal,
} from "../../redux/_reducers/sourceActionReducer";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Button } from "../PixxelUIKit/Button";
import * as Skeleton from "../PixxelUIKit/Skeleton";

const InfoModal = () => {
  const dispatch = useDispatch();
  const showInfoModal = useSelector(
    (state) => state.sourceActions.showInfoModal
  );
  const infoModalData = useSelector(
    (state) => state.sourceActions.infoModalData
  );
  return (
    <Modal
      open={showInfoModal}
      setOpen={(val) => {
        dispatch(setShowInfoModal(val));
      }}
      title={
        <div className="flex flex-row gap-[8px]">
          <Text type="highlighted" className="my-auto">
            <InfoCircledIcon className="w-[16px] w-[16px]" />
          </Text>
          <Text size="large" className="">
            Info
          </Text>
        </div>
      }
      onClose={() => {
        dispatch(setInfoModalData({}));
        dispatch(setShowInfoModal(false));
      }}
    >
      <div className="w-[500px] px-[20px] pb-4">
        <Title level="06" className="mt-2 mb-2">
          {infoModalData.name}
        </Title>
        {infoModalData?.loader ? (
          <Skeleton.SatelliteList />
        ) : (
          <Text type="secondary">{infoModalData.description}</Text>
        )}
      </div>
    </Modal>
  );
};

export default InfoModal;
