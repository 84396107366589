import type { FlagsValueType } from './FlagsProvider';

export const FLAGS = {
  BILLING_TOKEN: 'BILLING_TOKEN',
  AOI_DESIGN: 'AOI_DESIGN',
  THEME_SWITCH: 'THEME_SWITCH',
  NOTIFICATIONS: 'NOTIFICATIONS',
  STAC_TO_COG_IN_VISUALIZATION: 'STAC_TO_COG_IN_VISUALIZATION'
} as const;

export type FLAGS_KEYS = keyof typeof FLAGS;

export const FLAGS_LIST: {
  name: FLAGS_KEYS;
  defaultValue: FlagsValueType;
}[] = [
  {
    name: FLAGS.BILLING_TOKEN,
    defaultValue: false
  },

  {
    name: FLAGS.AOI_DESIGN,
    defaultValue: false
  },
  {
    name: FLAGS.THEME_SWITCH,
    defaultValue: false
  },
  {
    name: FLAGS.STAC_TO_COG_IN_VISUALIZATION,
    defaultValue: false
  },
  {
    name: FLAGS.NOTIFICATIONS,
    defaultValue: false
  }
];
