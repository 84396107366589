import React, { useState, useEffect, useRef } from "react";
import { styled } from "../../stitches.config";

export const Title = styled("h1", {
  fontFamily: "'Inter', sans-serif",
  wordBreak: "normal",
  // backgroundColor: '$accent80',
  variants: {
    level: {
      "01": {
        // color: '$defaultTone',
        fontSize: "38px",
        fontWeight: 600,
      },
      "02": {
        //    color: '$pixxelFont',
        fontSize: "30px",
        fontWeight: 600,
      },
      "03": {
        //    color: '$pixxelFont',
        fontSize: "24px",
        fontWeight: 600,
      },
      "04": {
        //    color: '$pixxelFont',
        fontSize: "20px",
        fontWeight: 600,
        "@scale_125": {
          fontSize: "18px",
        },
      },
      "05": {
        //    color: '$pixxelFont',
        fontSize: "16px",
        fontWeight: 600,
      },
      "06": {
        fontSize: "$2",
        fontWeight: 600,
      },
    },
    type: {
      primary: {
        color: "$defaultTone",
      },
      secondary: {
        color: "$pixxelFont",
      },
    },
  },
  defaultVariants: {
    level: "01",
    type: "primary",
  },
});

export const Text = styled("span", {
  variants: {
    type: {
      strong: {
        color: "$defaultTone",
        fontWeight: 700,
      },
      light: {
        color: "$defaultTone",
        fontWeight: 300,
      },
      regular: {
        color: "$defaultTone",
        fontWeight: 400,
      },
      secondary: {
        color: "$pixxelFont",
        fontWeight: 400,
      },
      danger: {
        color: "$error",
        fontWeight: 400,
      },
      highlighted: {
        color: "$accent",
        fontWeight: 400,
      },
    },
    size: {
      small: {
        fontSize: "$1",
      },
      medium: {
        fontSize: "$2",
        "@hd": {
          fontSize: "$1",
        },
        "@scale_125": {
          fontSize: "$1",
        },
      },
      large: {
        fontSize: "$3",
      },
    },
  },
  defaultVariants: {
    type: "regular",
    size: "medium",
  },
});
