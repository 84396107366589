import {
  Cross2Icon,
  ExclamationTriangleIcon,
  InfoCircledIcon
} from '@radix-ui/react-icons';
import { Button, Flex, Text } from '@raystack/apsara';

import useAnnouncement from '@src/hooks/useAnnouncement';
import {
  AnnouncementType,
  announcementMap
} from '@src/redux/_reducers/appReducer';

import styles from './AnnouncementBar.module.css';

type AnnouncementStyle = {
  icon: () => React.ReactElement;
  wrapperClass: string;
};

const announcementTypeStylesMap = new Map<AnnouncementType, AnnouncementStyle>([
  [
    announcementMap.WARNING,
    {
      icon: () => <ExclamationTriangleIcon className={styles.icon} />,
      wrapperClass: `${styles.baseWrapper} ${styles.warningWrapper}`
    }
  ],
  [
    announcementMap.INFO,
    {
      icon: () => <InfoCircledIcon className={styles.icon} />,
      wrapperClass: `${styles.baseWrapper} ${styles.warningWrapper}`
    }
  ],
  [
    announcementMap.ERROR,
    {
      icon: () => <ExclamationTriangleIcon className={styles.icon} />,
      wrapperClass: `${styles.baseWrapper} ${styles.errorWrapper}`
    }
  ]
]);

const NullIcon = () => <></>;

export default function AnnouncementBar() {
  const { announcement, isAnnouncementAvailable, closeAnnouncement } =
    useAnnouncement();

  const announcementStyles =
    announcement.type && announcementTypeStylesMap.has(announcement.type)
      ? (announcementTypeStylesMap.get(announcement.type) as AnnouncementStyle)
      : {
          icon: NullIcon,
          wrapperClass: styles.baseWrapper
        };

  return isAnnouncementAvailable ? (
    <div className={announcementStyles.wrapperClass}>
      <Flex
        justify={'center'}
        align={'center'}
        style={{ flex: 10, gap: '40px' }}
      >
        <Flex justify={'center'} align={'center'} gap='small'>
          {<announcementStyles.icon />}
          <Text size={2} className={styles.text}>
            {announcement.message}
          </Text>
        </Flex>
        {announcement.action && announcement.actionLabel ? (
          <Button
            className={styles.action}
            onClick={announcement.action}
            data-test-id='announcement-bar-action-btn'
          >
            {announcement.actionLabel}
          </Button>
        ) : null}
      </Flex>
      {announcement?.closable ? (
        <Button
          onClick={closeAnnouncement}
          data-test-id='announcement-bar-close-btn'
        >
          <Cross2Icon className={styles.icon} />
        </Button>
      ) : null}
    </div>
  ) : null;
}
