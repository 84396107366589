import { lazy, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useFrontier } from '@raystack/frontier/react';
import * as Sentry from '@sentry/browser';
import * as _ from 'lodash';
import useSWR from 'swr';

import Notifications from '@src/containers/Notifications';
import { IdentityContext } from '@src/contexts/identity/context';
import { FLAGS, useFlags } from '@src/feature-flags';
import useAnnouncement from '@src/hooks/useAnnouncement';
import useBilling from '@src/hooks/useBilling';
import useProjects from '@src/hooks/useProjects';
import {
  setActiveUser,
  setIAMDialogState
} from '@src/redux/_reducers/authReducer';
import { setTileLoader } from '@src/redux/_reducers/mapControl';
import { _initProjectLoad } from '@src/utils/helpers/ProjectHelpers';

import AnnouncementBar from './AnnouncementBar';
import AppLoader from './AppLoader';
import InfoModal from './InfoModal/InfoModal';
import { FullPageSpinner } from './PixxelUIKit/Spinner';

// lazy imports
const Sidebar = lazy(() => import('./Sidebar/NewSidebar'));
const IAMDialog = lazy(() => import('./Auth/IAMDialog'));

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const notificationIconRef = useRef(null);
  const { client, setUser } = useFrontier();
  const { isEnable } = useFlags();
  const { user, activeOrg, organizations } = useContext(IdentityContext);

  const workflowLoader = useSelector(state => state.workflow.workflowLoader);
  // const TileLoader = useSelector(state => state.map.tileLoader);
  // const tileMode = useSelector(state => state.projects.visualize.mode);
  const IAMDialogState = useSelector(state => state.auth.IAMDialogState);
  const showNotifications = useSelector(state => state.app.showNotifications);

  const dispatch = useDispatch();
  const Location = useLocation();
  const navigate = useNavigate();

  const { isBillingTokenEnabled } = useBilling();
  const { isAnnouncementAvailable } = useAnnouncement();
  const isThemeSwitchEnabled = isEnable(FLAGS.THEME_SWITCH);

  const { getProjects } = useProjects({
    onSuccess: _initProjectLoad
  });

  async function closeIamDialog() {
    dispatch(setIAMDialogState({ isOpen: false, route: '' }));
    await getProjects(activeOrg?.id);
  }

  useEffect(() => {
    if (Location?.state?.dialogPath) {
      dispatch(
        setIAMDialogState({ isOpen: true, route: Location?.state?.dialogPath })
      );

      navigate(Location, { state: {} });
    }
  }, [Location?.state, dispatch]);

  const { isLoading: userLoader } = useSWR(
    client ? '/self' : null,
    () => client?.frontierServiceGetCurrentUser(),
    {
      onSuccess: ({ data }) => {
        if (_.isEmpty(user)) {
          dispatch(setActiveUser(data?.user));
          setUser(data?.user);
        }
      }
    }
  );

  useEffect(() => {
    if (user?.id) {
      Sentry.setUser({
        id: user?.id,
        title: user?.title,
        email: user?.email
      });
    }
  }, [user]);

  // reset tile loader on location change
  useEffect(() => {
    if (Location?.pathname) {
      dispatch(setTileLoader(false));
    }
  }, [Location?.pathname, dispatch]);

  const isOrgEnabled = useMemo(() => {
    return activeOrg?.state === 'enabled';
  }, [activeOrg?.state]);

  const appLoader = userLoader;

  const wrapperClass = isAnnouncementAvailable
    ? 'main-wrapper-with-banner' // 'flex h-screen'
    : 'main-wrapper';

  // const isExplorePath = useMemo(() => {
  //   return matchPath('/:orgName', Location?.pathname) !== null;
  // }, [Location?.pathname]);

  return (
    <>
      {appLoader ? (
        <AppLoader />
      ) : _.isEmpty(user) ? (
        <Navigate replace to='/login' />
      ) : _.isEmpty(activeOrg) && organizations.length === 0 ? (
        <Navigate replace to='/organizations' />
      ) : !isOrgEnabled ? (
        <Navigate replace to={`/organizations/${activeOrg.id}`} />
      ) : (
        <>
          <AnnouncementBar />

          <div className={wrapperClass} id='main-wrapper'>
            {/* sidebar */}
            <Sidebar ref={{ notificationIconRef }} />

            {/* App inner loaders */}
            {workflowLoader && <FullPageSpinner text='Loading Workflow...' />}

            <div
              style={{ height: '100%', width: '100%', position: 'relative' }}
            >
              <Component {...rest} />
              {showNotifications ? (
                <Notifications notificationIconRef={notificationIconRef} />
              ) : null}
            </div>

            <InfoModal />

            <IAMDialog
              organizationId={activeOrg?.id}
              onClose={closeIamDialog}
              dialogState={IAMDialogState}
              isBillingTokenEnabled={isBillingTokenEnabled}
              isThemeSwitchEnabled={isThemeSwitchEnabled}
            />
          </div>
        </>
      )}
    </>
  );
};
