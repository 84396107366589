import React from "react";
import { ReactComponent as Logo } from "@src/containers/LoginPage/Logo.svg";
import { ScaleLoader } from "react-spinners";

const AppLoader = () => {
  return (
    <div className="w-screen h-screen bg-[var(--background-base)] flex justify-between">
      <div className="m-auto w-[100px]">
        <Logo className="w-[100px] h-auto text-[var(--foreground-base)]" />
        <div className="w-full flex justify-between">
          <ScaleLoader
            className="mx-auto mt-[12px]"
            color={"#43C7EF"}
            loading={true}
            size={150}
          />
        </div>
      </div>
    </div>
  );
};

export default AppLoader;
