import { useEffect } from "react";

export const useOutsideClick = ({
  triggerRef,
  contentRef,
  callback,
  checkFn = () => true,
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        triggerRef.current &&
        !triggerRef.current.contains(event.target) &&
        contentRef.current &&
        !contentRef.current.contains(event.target) &&
        checkFn(event)
      ) {
        callback();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback, contentRef, triggerRef]);
};
