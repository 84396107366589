import { Text } from "@raystack/apsara";
import { useFrontier } from "@raystack/frontier/react";
import { styled } from "@src/stitches.config";
import { useState, useRef } from "react";
import { ChevronLeftIcon, ExitIcon } from "@radix-ui/react-icons";
import { useOutsideClick } from "@src/hooks/useOutsideClick";
import { LogoutUser } from "@src/utils/userHandler";
import { useLocation, useNavigate, Link } from "react-router-dom";

const LogoutDropdownWrapper = styled("div", {
  position: "relative",
  alignSelf: "flex-end",
  margin: "40px 40px 32px 0",
  userSelect: "none",
});

const LogoutDropdown = styled("div", {
  cursor: "pointer",
  padding: "$4 $5",
  display: "flex",
  flexDirection: "column",
  gap: "$2",
});

const DropDown = styled("div", {
  position: "absolute",
  top: "100%",
  right: 0,
  padding: "$1",
  minWidth: "180px",
  width: "100%",
  borderRadius: "var(--XS)",
  border: "1px solid var(--border-subtle)",
  background: "var(--background-base)",
  boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.09)",
});

const LogoutBtn = styled("div", {
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  fontSize: "$1",
  gap: "var(--gap_3)",
  padding: "var(--gap_3)",
  background: "var(--background-base)",
  color: "var(--foreground-base)",
  "&:hover": {
    background: "var(--background-base-hover)",
  },
});

const OnboardingActionsWrapper = styled("div", {
  display: "flex",
  width: "100%",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  alignItems: "center",
});

const BackBtnLink = styled(Link, {
  display: "flex",
  gap: "4px",
  marginLeft: "40px",
  color: "var(--foreground-base)",
});

export default function OnboardingLogout() {
  const [showDropdown, setShowDropdown] = useState(false);
  const btnRef = useRef(null);
  const bodyRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useFrontier();

  const prevOrg = location?.state?.prevOrg;

  useOutsideClick({
    triggerRef: btnRef,
    contentRef: bodyRef,
    callback: () => setShowDropdown(false),
  });

  function toggleDropdown() {
    setShowDropdown((prev) => !prev);
  }

  function logout() {
    LogoutUser(navigate);
  }

  return (
    <OnboardingActionsWrapper>
      <LogoutDropdownWrapper>
        <LogoutDropdown ref={btnRef} onClick={toggleDropdown}>
          <Text size={1} style={{ color: "var(--foreground-subtle)" }}>
            Logged in as:
          </Text>
          <Text size={2} style={{ color: "var(--foreground-base)" }}>
            {user?.email}
          </Text>
        </LogoutDropdown>
        {showDropdown ? (
          <DropDown ref={bodyRef}>
            <LogoutBtn onClick={logout}>
              <ExitIcon style={{ size: "16px" }} />
              <span>Log out</span>
            </LogoutBtn>
          </DropDown>
        ) : null}
      </LogoutDropdownWrapper>
      {prevOrg ? (
        <BackBtnLink to={`/${prevOrg}`}>
          <ChevronLeftIcon />
          <Text>Back to Aurora</Text>
        </BackBtnLink>
      ) : null}
    </OnboardingActionsWrapper>
  );
}
