import { FrontierClientOptions } from '@raystack/frontier/react/dist';

import {
  DEFAULT_THEME,
  EMAILS,
  PLAN_DOWNGRADE_MESSAGES
} from '@src/utils/constants/APP';
import { US_DATE_FORMAT, US_SHORT_DATE_FORMAT } from '@src/utils/dateUtils';

const showBasePlan = import.meta.env.VITE_BILLING_SHOW_BASE_PLAN === 'true';

const getFrontierConfig = (): FrontierClientOptions => {
  const frontierEndpoint = import.meta.env.VITE_FRONTIER_URL;
  const currentHost = window?.location?.origin || 'http://localhost:3000';
  return {
    endpoint: frontierEndpoint,
    redirectLogin: `${currentHost}/login`,
    redirectSignup: `${currentHost}/signup`,
    redirectMagicLinkVerify: `${currentHost}/magiclink-verify`,
    callbackUrl: `${currentHost}/callback`,
    dateFormat: US_DATE_FORMAT,
    shortDateFormat: US_SHORT_DATE_FORMAT,
    billing: {
      supportEmail: EMAILS.BILLING,
      successUrl: `${currentHost}/payment/success`,
      cancelUrl: `${currentHost}/payment/cancel`,
      showPerMonthPrice: true,
      basePlan: showBasePlan
        ? {
            title: 'Standard Plan',
            name: 'standard_plan_plan',
            description: 'Order images',
            features: {
              'Wallet Recharge': 'No Limit',
              'Access to Pixxel images': 'true',
              'Task Pixxel Satellites': 'true',
              'Open Source Data': 'true',
              Collaboration: 'true'
            }
          }
        : undefined
    },
    theme: DEFAULT_THEME,
    messages: {
      billing: {
        plan_change: {
          'yearly_enterprise_plan_plan:standard_plan_plan':
            PLAN_DOWNGRADE_MESSAGES.enterprise_to_standard,
          'monthly_enterprise_plan_plan:standard_plan_plan':
            PLAN_DOWNGRADE_MESSAGES.enterprise_to_standard,
          'yearly_enterprise_plan_plan:monthly_professional_plan_plan':
            PLAN_DOWNGRADE_MESSAGES.enterprise_to_professional,
          'monthly_enterprise_plan_plan:monthly_professional_plan_plan':
            PLAN_DOWNGRADE_MESSAGES.enterprise_to_professional,
          'yearly_enterprise_plan_plan:yearly_professional_plan_plan':
            PLAN_DOWNGRADE_MESSAGES.enterprise_to_professional,
          'monthly_enterprise_plan_plan:yearly_professional_plan_plan':
            PLAN_DOWNGRADE_MESSAGES.enterprise_to_professional,
          'monthly_professional_plan_plan:standard_plan_plan':
            PLAN_DOWNGRADE_MESSAGES.professional_to_standard,
          'yearly_professional_plan_plan:standard_plan_plan':
            PLAN_DOWNGRADE_MESSAGES.professional_to_standard
        }
      }
    }
  };
};

export const frontierConfig = getFrontierConfig();
